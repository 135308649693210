import { Component, OnInit, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs';
import { DictDto } from 'src/app/models/dict-dto.model';
import { DocumentType, ImageType } from 'src/app/models/enums';
import { FlatFullDto } from 'src/app/models/flat-full-dto.model';
import { FlatImageDto } from 'src/app/models/flat-image-dto.model';
import { ImageFilesWithType } from 'src/app/models/image-files-with-type.model';
import { InvestmentShortDto } from 'src/app/models/investment-active-dto.model';
import { AlertService, MessageSeverity } from 'src/app/services/alert.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { DictionaryService } from 'src/app/services/dictionary.service';
import { FileService } from 'src/app/services/file.service';
import { FlatService } from 'src/app/services/flat.service';
import { InvestmentService } from 'src/app/services/investment.service';
import { Utilities } from 'src/app/services/utilities';

@Component({
  selector: 'app-flat-editor',
  templateUrl: './flat-editor.component.html',
  styleUrls: ['./flat-editor.component.scss']
})
export class FlatEditorComponent implements OnInit {
  public uniqueId: string = Utilities.uniqueId();
  public flatEdit: FlatFullDto = new FlatFullDto();
  public investments: InvestmentShortDto[];
  public propertyTypes: DictDto[];
  //public stageTypes: DictDto[];
  public statusTypes: DictDto[];
  public completionTermTypes: DictDto[];
  public worldDirectionTypes: DictDto[];
  public parkingTypes: DictDto[];
  public surroundingTypes: DictDto[];
  public flatAdvantageTypes: DictDto[];
  public promotionTypes: DictDto[];
  // public documentTypes: DictDto[];

  private getTranslation: (key: string) => any;
  public ImageTypeEnum = ImageType;
  // public DocumentTypeEnum = DocumentType;
  public selectedImages: File[] = [];
  public selectedPlans: File[] = [];
  // public selectedDocs: {file: File, typeId: number }[] = [];
  // public existingPictures: InvestmentImageDto[];
  public existingPlans: FlatImageDto[];
  // public existingDocs: InvestmentDocumentDto[];
  // public documentTypeSelectedId: number = DocumentType.SCHEDULE;


  public isSavingInProgress = false;
  public showValidationErrors = false;

  public formResetToggle = true;

  public changesSavedCallback: () => void;
  public changesFailedCallback: () => void;
  public changesCancelledCallback: () => void;

  @ViewChild('f')
  public form;

  constructor(
    private alertService: AlertService,
    private dictionaryService: DictionaryService,
    private translationService: AppTranslationService,
    private fileService: FileService,
    private flatService: FlatService,
    private investmentService: InvestmentService
    ) {
  }

  ngOnInit() {
    this.getTranslation = (key: string) => this.translationService.getTranslation(key);

    forkJoin({
      invests: this.investmentService.getInvestmentsActive(),
      props: this.dictionaryService.getPropertyTypes(),
      statuses: this.dictionaryService.getStatusTypes(),
      termTypes: this.dictionaryService.getCompletionTermTypes(),
      directions: this.dictionaryService.getWorldDirectionTypes(),
      parkingTypes: this.dictionaryService.getParkingTypes(),
      surroundings: this.dictionaryService.getSurroundingTypes(),
      advTypes: this.dictionaryService.getFlatAdvantageTypes(),
      promotions: this.dictionaryService.getPromotionTypes(),

      // docTypes: this.dictionaryService.getDocumentTypes(),

      }).subscribe({
        next: (results) => {
          this.investments = results.invests;
          this.propertyTypes = results.props;
          this.statusTypes = results.statuses;
          this.completionTermTypes = results.termTypes;
          this.worldDirectionTypes = results.directions;
          this.parkingTypes = results.parkingTypes;
          this.surroundingTypes = results.surroundings;
          this.flatAdvantageTypes = results.advTypes;
          this.promotionTypes = results.promotions;
          // this.documentTypes = results.docTypes;
        },
        error: error => this.onDataLoadFailed(error)
    });
  }

  public newFlat(investmentId?: number): void {
    this.flatEdit = new FlatFullDto();
    if (investmentId > 0) {
      this.flatEdit.investmentId = investmentId;
    }
  }

  public editFlat(flatId: number): void {
    this.flatService.getFlatById(flatId)
    .subscribe({
      next: result => {
        this.flatEdit = result;
        // this.existingPictures = this.flatEdit.images.filter(image => image.imageTypeId === ImageType.PICTURE);
        this.existingPlans = this.flatEdit.images.filter(image => image.imageTypeId === ImageType.FLOOR_PLAN);
        // this.existingDocs = this.flatEdit.docs;
      },
      error: error => this.onDataLoadFailed(error)
    });
  }

  public save(): void {
    this.isSavingInProgress = true;
    this.alertService.startLoadingMessage('Saving changes... ');
    if (this.flatEdit.flatId) {
      this.updateFlat();
    } else {
      this.addFlat();
    }
  }

  private addFlat() {
    this.flatService.addFlat(this.flatEdit)
      .subscribe({
        next: flatId => {
          if (flatId > 0) {
            this.uploadImages(flatId);
            // this.uploadDocuments(flatId);
          } else {
            this.alertService.showStickyMessage('Save Error', this.getTranslation('flats.editor.ImageSaveError'), MessageSeverity.error);
          }
          this.saveSuccessHelper();
          this.alertService.showMessage(this.getTranslation('flats.editor.FlatAdd'),
            this.getTranslation('flats.editor.FlatAddSuccess'), MessageSeverity.success);
        },
        error: error => this.saveFailedHelper(error)
      });
  }

  private updateFlat() {
    this.flatService.updateFlat(this.flatEdit)
      .subscribe({
        next: _ => {
          this.uploadImages(this.flatEdit.flatId);
          // this.uploadDocuments(this.flatEdit.flatId);
          this.saveSuccessHelper();
          this.alertService.showMessage(this.getTranslation('flats.editor.FlatEdit'),
            this.getTranslation('flats.editor.FlatEditSuccess'), MessageSeverity.success);
        },
        error: error => this.saveFailedHelper(error)
      });
  }

  private saveSuccessHelper(): void {

    this.isSavingInProgress = false;
    this.alertService.stopLoadingMessage();
    this.showValidationErrors = false;
    this.resetForm();

    if (this.changesSavedCallback) {
      this.changesSavedCallback();
    }
  }

  private saveFailedHelper(error: any) {
    this.isSavingInProgress = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Save Error', 'The below errors occured whilst saving your changes:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);

    if (this.changesFailedCallback) {
      this.changesFailedCallback();
    }
  }

  public cancel() {
    this.showValidationErrors = false;
    this.resetForm();

    this.alertService.showMessage('Cancelled', this.getTranslation('flats.editor.CancelAction'), MessageSeverity.default);
    this.alertService.resetStickyMessage();

    if (this.changesCancelledCallback) {
      this.changesCancelledCallback();
    }
  }

  public resetForm(replace = false) {
    if (!replace) {
      this.form.reset();
      this.clearSelectedFiles();
      // this.documentTypeSelectedId = DocumentType.SCHEDULE;
    } else {
      this.formResetToggle = false;

      setTimeout(() => {
        this.formResetToggle = true;
      });
    }
  }

  private onDataLoadFailed(error: any): void {
    this.alertService.stopLoadingMessage();

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  public onImagesSelected(files: FileList, type: ImageType): void {
    const typeMap = {
      [ImageType.PICTURE]: this.selectedImages,
      [ImageType.FLOOR_PLAN]: this.selectedPlans,
    };

    const fileListArray = Array.from(files);
    for (const file of fileListArray) {

      const selectedFiles = typeMap[type];
      if (selectedFiles) {
        selectedFiles.push(file);
      }
    }
  }

  public humanFileSize(bytes: number): string {
    if (Math.abs(bytes) < 1024) {
      return bytes + ' B';
    }
    const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let u = -1;
    do {
      bytes /= 1024;
      u++;
    } while (Math.abs(bytes) >= 1024 && u < units.length - 1);
    return bytes.toFixed(1) + ' ' + units[u];
  }

  private uploadImages(flatId: number): void {
    if (!this.selectedImages.length && !this.selectedPlans.length) {
      return;
    }
    const images: ImageFilesWithType[] = [
      { images: this.selectedImages, typeId: ImageType.PICTURE },
      { images: this.selectedPlans, typeId: ImageType.FLOOR_PLAN }
    ];
    this.fileService.uploadImagesForFlat(flatId, images)
    .subscribe({
      next: _ => {
        this.clearSelectedFiles();
      },
      error: error => this.saveFailedHelper(error)
    });
  }

  private clearSelectedFiles() {
    this.selectedImages = [];
    this.selectedPlans = [];
  }

  public deleteImage(image: FlatImageDto, imageType: ImageType): void {
    this.fileService.deleteImageForFlat(image.flatImageId)
    .subscribe({
      next: rowsAffected => {
        if (rowsAffected > 0) {
          this.alertService.showMessage(this.getTranslation('flats.editor.ImageDeleted'), this.getTranslation('flats.editor.ImageDeletedSuccess'), MessageSeverity.success);
          switch (imageType) {
            // case ImageType.PICTURE:
            //   this.existingPictures = this.existingPictures.filter(img => img.investmentImageId !== image.investmentImageId);
            //   break;
            case ImageType.FLOOR_PLAN:
              this.existingPlans = this.existingPlans.filter(img => img.flatImageId !== image.flatImageId);
              break;
            default:
              throw new Error(`Not supported imageType: ${imageType}`);
          }
        }
      },
      error: error => this.saveFailedHelper(error)
    });
  }
}
