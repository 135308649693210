<div class="d-flex h-90">
  <div class="reset-container m-auto">
    <div class="card boxshadow">
      <div class="card-header bg-primary text-white">
        <span>
          <i class="fa fa-refresh"></i>
          <i class="fa fa-lock overlay-icon-lower-right"></i>
        </span>
        {{'resetPassword.ResetPassword' | translate}}
      </div>
      <div class="card-body">
        <div *ngIf="isSuccess">
          <p class="card-text">{{'resetPassword.PasswordResetSuccessful' | translate}} <a class="card-link" routerLink="/login">{{'resetPassword.ClickToLogin' | translate}}</a>.</p>
        </div>
        <div class="col-md-10 offset-md-1 px-md-3">
          <form *ngIf="formResetToggle && !isSuccess" class="reset" name="resetForm" #f="ngForm" novalidate
                (ngSubmit)="f.form.valid ? resetPassword() :
                (!username.valid && showErrorAlert('resetPassword.alerts.UsernameOrEmailRequired', 'resetPassword.alerts.EnterUsernameOrEmail'));

                (!password.valid && showErrorAlert('resetPassword.alerts.NewPasswordRequired', 'resetPassword.alerts.EnterNewValidPassword'));

                (password.valid && confirmPassword.errors?.required && showErrorAlert('resetPassword.alerts.ConfirmationPasswordRequired', 'resetPassword.alerts.EnterConfirmationPassword'));
                (password.valid && confirmPassword.errors?.validateEqual && showErrorAlert('resetPassword.alerts.PasswordMismatch', 'resetPassword.alerts.NewAndConfirmationPasswordMismatch'))">
            <div class="mb-3 row">
              <label class="col-form-label text-md-end col-md-4" for="reset-username">{{'resetPassword.UsernameOrEmail' | translate}}</label>
              <div class="col-md-8">
                <input type="text" id="reset-username" name="username" placeholder="{{'resetPassword.UsernameOrEmailPlaceholder' | translate}}" class="form-control" [ngClass]="{'is-valid': f.submitted && username.valid, 'is-invalid' : f.submitted && !username.valid}"
                       [(ngModel)]="usernameOrEmail" #username="ngModel" required />
                <span class="invalid-feedback" *ngIf="f.submitted && !username.valid">
                  {{'resetPassword.UsernameOrEmailRequired' | translate}}
                </span>
              </div>
            </div>

            <div class="mb-3 row">
              <label class="col-form-label text-md-end col-md-4" for="reset-password">{{'resetPassword.NewPassword' | translate}}</label>
              <div class="col-md-8">
                <input type="password" id="reset-password" name="password" placeholder="{{'resetPassword.NewPasswordPlaceholder' | translate}}" class="form-control" [ngClass]="{'is-valid': f.submitted && password.valid, 'is-invalid' : f.submitted && !password.valid}"
                       [(ngModel)]="newPassword" #password="ngModel" required minlength="6" validateEqual="confirmPassword" reverse="true" />
                <span class="invalid-feedback" *ngIf="f.submitted && !password.valid">
                  {{'resetPassword.NewPasswordRequired' | translate}}
                </span>
              </div>
            </div>

            <div class="mb-3 row">
              <label class="col-form-label text-md-end col-md-4" for="reset-confirm-password">{{'resetPassword.ConfirmPassword' | translate}}</label>
              <div class="col-md-8">
                <input type="password" id="reset-confirm-password" name="confirmPassword" placeholder="{{'resetPassword.ConfirmPasswordPlaceholder' | translate}}" class="form-control" [ngClass]="{'is-valid': f.submitted && confirmPassword.valid, 'is-invalid' : f.submitted && !confirmPassword.valid}"
                       [(ngModel)]="confirmationPassword" #confirmPassword="ngModel" required validateEqual="password" />
                <span class="invalid-feedback" *ngIf="f.submitted && confirmPassword.errors?.required">
                  {{'resetPassword.ConfirmPasswordRequired' | translate}}
                </span>
                <span class="invalid-feedback" *ngIf="f.submitted && confirmPassword.errors?.validateEqual && !confirmPassword.errors?.required">
                  {{'resetPassword.PasswordMismatch' | translate}}
                </span>
              </div>
            </div>


            <div class="mb-3 row">
              <div ngPreserveWhitespaces class="offset-md-4 col-md-8">
                <button *ngIf="!isSuccess" type="button" routerLink="/" class="btn btn-outline-danger" [disabled]="isLoading"><i class='fa fa-times'></i> {{'resetPassword.Cancel' | translate}}</button>
                <button *ngIf="!isSuccess" type="submit" class="btn btn-primary" [disabled]="isLoading">
                  <i *ngIf="isLoading" class='fa fa-circle-o-notch fa-spin'></i> {{isLoading ? ('resetPassword.Saving' | translate) : ('resetPassword.Submit' | translate)}}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
