<div>

  <form *ngIf="formResetToggle" autocomplete="off" name="investmentForm" #f="ngForm" novalidate
        (ngSubmit)="save()">




    <ul class="nav nav-tabs mb-3" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link active" id="data-tab" data-bs-toggle="tab" data-bs-target="#data-container" type="button" role="tab" aria-selected="true">
          {{'investments.editor.InvestmentDataDesc' | translate}}</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="address-tab" data-bs-toggle="tab" data-bs-target="#address-container" type="button" role="tab" aria-selected="false">
          {{'investments.editor.Address' | translate}}</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="files-tab" data-bs-toggle="tab" data-bs-target="#files-container" type="button" role="tab" aria-selected="false">
          {{'investments.editor.Files' | translate}}</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="flats-tab" data-bs-toggle="tab" data-bs-target="#flats-container" type="button" role="tab" aria-selected="false">
          {{'investments.editor.Flats' | translate}}</button>
      </li>
      <li class="nav-item ms-auto">
        <button type="submit" class="btn btn-primary" [disabled]="isSavingInProgress">
          <i *ngIf="!isSavingInProgress" class='fa fa-save'></i>
          <i *ngIf="isSavingInProgress" class='fa fa-circle-o-notch fa-spin'></i> {{isSavingInProgress ? ('investments.editor.Saving' | translate) : ('investments.editor.Save' | translate)}}
        </button>
      </li>
    </ul>

    <div class="tab-content mb-3" id="investmentTabContent">

      <!--  Data  -->
      <div class="tab-pane fade show active" id="data-container" role="tabpanel" aria-labelledby="data-tab">

        <div class="row">
          <label class="col-form-label col-sm-2" for="investmentName-{{uniqueId}}">{{'investments.editor.InvestmentName' | translate}}</label>
          <div class="col-sm-4">
            <input type="text" required attr.id="investmentName-{{uniqueId}}" name="investmentName" placeholder="investmentName" class="form-control" [(ngModel)]="investmentEdit.investmentName" />
          </div>
          <label class="col-form-label col-sm-1  text-end" for="currency-{{uniqueId}}">{{'investments.editor.Currency' | translate}}</label>
          <div class="col-sm-1">
            <input type="text" required attr.id="currency-{{uniqueId}}" name="currency" placeholder="currency" class="form-control" [(ngModel)]="investmentEdit.currency" />
          </div>
          <label class="col-form-label col-sm-2 text-end" for="isDisabled-{{uniqueId}}">{{'investments.editor.IsDisabled' | translate}}</label>
          <div class="col-sm-2">
            <div class="form-check form-switch fs-5 pt-sm-1">
              <input type="checkbox" attr.id="isDisabled-{{uniqueId}}" name="isDisabled" placeholder="isDisabled" class="form-check-input" [(ngModel)]="investmentEdit.isDisabled" />
            </div>
          </div>
        </div>

        <div class="row"><div class="col-lg-12"><hr class="hr-separator edit-separator"/></div></div>

        <div class="row">
          <label class="col-form-label col-sm-2" for="developerName-{{uniqueId}}">{{'investments.editor.DeveloperName' | translate}}</label>
          <div class="col-sm-4">
            <input type="text" required attr.id="developerName-{{uniqueId}}" name="developerName" placeholder="developerName" class="form-control" [(ngModel)]="investmentEdit.developerName" />
          </div>
          <label class="col-form-label col-sm-2" for="developerUrl-{{uniqueId}}">{{'investments.editor.DeveloperUrl' | translate}}</label>
          <div class="col-sm-4">
            <input type="text" attr.id="developerUrl-{{uniqueId}}" name="developerUrl" placeholder="developerUrl" class="form-control" [(ngModel)]="investmentEdit.developerUrl" />
          </div>
        </div>

        <div class="row"><div class="col-lg-12"><hr class="hr-separator edit-separator"/></div></div>

        <div class="row">
          <label class="col-form-label col-sm-2" for="priceFrom-{{uniqueId}}">{{'investments.editor.PriceFrom' | translate}}</label>
          <div class="col-sm-4">
            <input type="number" required attr.id="priceFrom-{{uniqueId}}" name="priceFrom" placeholder="priceFrom" class="form-control" [(ngModel)]="investmentEdit.priceFrom" />
          </div>
          <label class="col-form-label col-sm-2" for="priceTo-{{uniqueId}}">{{'investments.editor.PriceTo' | translate}}</label>
          <div class="col-sm-4">
            <input type="number" required attr.id="priceTo-{{uniqueId}}" name="priceTo" placeholder="priceTo" class="form-control" [(ngModel)]="investmentEdit.priceTo" />
          </div>
        </div>

        <div class="row"><div class="col-lg-12"><hr class="hr-separator edit-separator"/></div></div>

        <div class="row">
          <label class="col-form-label col-sm-2" for="priceMeterFrom-{{uniqueId}}">{{'investments.editor.PriceMeterFrom' | translate}}</label>
          <div class="col-sm-4">
            <input type="number" required attr.id="priceMeterFrom-{{uniqueId}}" name="priceMeterFrom" placeholder="priceMeterFrom" class="form-control" [(ngModel)]="investmentEdit.priceMeterFrom" />
          </div>
          <label class="col-form-label col-sm-2" for="priceMeterTo-{{uniqueId}}">{{'investments.editor.PriceMeterTo' | translate}}</label>
          <div class="col-sm-4">
            <input type="number" required attr.id="priceMeterTo-{{uniqueId}}" name="priceMeterTo" placeholder="priceMeterTo" class="form-control" [(ngModel)]="investmentEdit.priceMeterTo" />
          </div>
        </div>

        <div class="row"><div class="col-lg-12"><hr class="hr-separator edit-separator"/></div></div>

        <div class="row">
          <label class="col-form-label col-sm-2" for="squareAreaFrom-{{uniqueId}}">{{'investments.editor.SquareAreaFrom' | translate}}</label>
          <div class="col-sm-2">
            <input type="number" attr.id="squareAreaFrom-{{uniqueId}}" name="squareAreaFrom" placeholder="squareAreaFrom" class="form-control" [(ngModel)]="investmentEdit.squareAreaFrom" />
          </div>
          <label class="col-form-label col-sm-2" for="squareAreaTo-{{uniqueId}}">{{'investments.editor.SquareAreaTo' | translate}}</label>
          <div class="col-sm-2">
            <input type="number" attr.id="squareAreaTo-{{uniqueId}}" name="squareAreaTo" placeholder="squareAreaTo" class="form-control" [(ngModel)]="investmentEdit.squareAreaTo" />
          </div>
          <label class="col-form-label col-sm-3 text-end" for="squareAreaUnit-{{uniqueId}}">{{'investments.editor.SquareAreaUnit' | translate}}</label>
          <div class="col-sm-1">
            <input type="text" attr.id="squareAreaUnit-{{uniqueId}}" name="squareAreaUnit" placeholder="squareAreaUnit" class="form-control" [(ngModel)]="investmentEdit.squareAreaUnit" />
          </div>
        </div>

        <div class="row"><div class="col-lg-12"><hr class="hr-separator edit-separator"/></div></div>

        <div class="row">
          <label class="col-form-label col-sm-2" for="floorFrom-{{uniqueId}}">{{'investments.editor.FloorFrom' | translate}}</label>
          <div class="col-sm-2">
            <input type="text" attr.id="floorFrom-{{uniqueId}}" name="floorFrom" placeholder="floorFrom" class="form-control" [(ngModel)]="investmentEdit.floorFrom" />
          </div>
          <label class="col-form-label col-sm-2" for="floorTo-{{uniqueId}}">{{'investments.editor.FloorTo' | translate}}</label>
          <div class="col-sm-2">
            <input type="text" attr.id="floorTo-{{uniqueId}}" name="floorTo" placeholder="floorTo" class="form-control" [(ngModel)]="investmentEdit.floorTo" />
          </div>
        </div>

        <div class="row"><div class="col-lg-12"><hr class="hr-separator edit-separator"/></div></div>

        <div class="row">
          <label class="col-form-label col-sm-2" for="completionTermTypeFromId-{{uniqueId}}">{{'investments.editor.CompletionTermTypeFromId' | translate}}</label>
          <div class="col-sm-4">
            <ng-select id="completionTermTypeFromId-{{uniqueId}}" name="completionTermTypeFromId" [(ngModel)]="investmentEdit.completionTermTypeFromId" class="form-control">
              <ng-option *ngFor="let completionTermType of completionTermTypes" [value]="completionTermType.id">
                {{ completionTermType.name }}
              </ng-option>
            </ng-select>
          </div>
          <label class="col-form-label col-sm-2" for="completionTermTypeToId-{{uniqueId}}">{{'investments.editor.CompletionTermTypeToId' | translate}}</label>
          <div class="col-sm-4">
            <ng-select id="completionTermTypeToId-{{uniqueId}}" name="completionTermTypeToId" [(ngModel)]="investmentEdit.completionTermTypeToId" class="form-control">
              <ng-option *ngFor="let completionTermType of completionTermTypes" [value]="completionTermType.id">
                {{ completionTermType.name }}
              </ng-option>
            </ng-select>
          </div>
        </div>

        <div class="row"><div class="col-lg-12"><hr class="hr-separator edit-separator"/></div></div>

        <div class="row">
          <label class="col-form-label col-sm-2" for="parkingTypeIds-{{uniqueId}}">{{'investments.editor.ParkingType' | translate}}</label>
          <div class="col-sm-6">
            <ng-select id="parkingTypeIds-{{uniqueId}}" name="parkingTypeIds" [multiple]="true" [(ngModel)]="investmentEdit.parkingTypeIds" class="form-control" placeholder="parkingTypeIds">
              <ng-option *ngFor="let parkingType of parkingTypes" [value]="parkingType.id">
                {{ parkingType.name }}
              </ng-option>
            </ng-select>
          </div>
          <label class="col-form-label col-sm-2 text-end">{{'investments.editor.IsLift' | translate}}</label>
          <div class="col-sm-2">
            <div class="form-check form-switch fs-5 pt-sm-1">
              <input name="isLift-{{uniqueId}}" [(ngModel)]="investmentEdit.isLift" class="form-check-input" type="checkbox" id="isLift-{{uniqueId}}">
            </div>
          </div>
        </div>

        <div class="row"><div class="col-lg-12"><hr class="hr-separator edit-separator"/></div></div>

        <div class="row">
          <label class="col-form-label col-sm-2" for="parkingDesc-{{uniqueId}}">{{'investments.editor.ParkingDesc' | translate}}</label>
          <div class="col-sm-10">
            <input type="text" attr.id="parkingDesc-{{uniqueId}}" name="parkingDesc" placeholder="parkingDesc" class="form-control" [(ngModel)]="investmentEdit.parkingDesc" />
          </div>
        </div>

        <div class="row"><div class="col-lg-12"><hr class="hr-separator edit-separator"/></div></div>

        <div class="row">
          <label class="col-form-label col-sm-2" for="storageRoomDesc-{{uniqueId}}">{{'investments.editor.StorageRoomDesc' | translate}}</label>
          <div class="col-sm-10">
            <input type="text" attr.id="storageRoomDesc-{{uniqueId}}" name="storageRoomDesc" placeholder="storageRoomDesc" class="form-control" [(ngModel)]="investmentEdit.storageRoomDesc" />
          </div>
        </div>

        <div class="row"><div class="col-lg-12"><hr class="hr-separator edit-separator"/></div></div>

        <div class="row">
          <label class="col-form-label col-sm-2" for="distanceFromCenter-{{uniqueId}}">{{'investments.editor.DistanceFromCenter' | translate}}</label>
          <div class="col-sm-4">
            <input type="text" attr.id="distanceFromCenter-{{uniqueId}}" name="distanceFromCenter" placeholder="distanceFromCenter" class="form-control" [(ngModel)]="investmentEdit.distanceFromCenter" />
          </div>
          <label class="col-form-label col-sm-2" for="openDays-{{uniqueId}}">{{'investments.editor.OpenDays' | translate}}</label>
          <div class="col-sm-4">
            <input type="text" attr.id="openDays-{{uniqueId}}" name="openDays" placeholder="openDays" class="form-control" [(ngModel)]="investmentEdit.openDays" />
          </div>
        </div>

        <div class="row"><div class="col-lg-12"><hr class="hr-separator edit-separator"/></div></div>

        <div class="row">
          <label class="col-form-label col-sm-2" for="advantageTypeIds-{{uniqueId}}">{{'investments.editor.AdvantageTypes' | translate}}</label>
          <div class="col-sm-10">
            <ng-select id="advantageTypeIds-{{uniqueId}}" name="advantageTypeIds" [multiple]="true" [(ngModel)]="investmentEdit.advantageTypeIds" class="form-control" placeholder="advantageTypeIds">
              <ng-option *ngFor="let advType of invAdvantageTypes" [value]="advType.id">
                {{ advType.name }}
              </ng-option>
            </ng-select>
          </div>
        </div>

        <div class="row"><div class="col-lg-12"><hr class="hr-separator edit-separator"/></div></div>

        <div class="row">
          <label class="col-form-label col-sm-2" for="propertyTypeId-{{uniqueId}}">{{'investments.editor.PropertyType' | translate}}</label>
          <div class="col-sm-4">
            <ng-select id="propertyTypeId-{{uniqueId}}" name="propertyTypeId" [(ngModel)]="investmentEdit.propertyTypeId" placeholder="propertyTypeId" class="form-control">
              <ng-option *ngFor="let propType of propertyTypes" [value]="propType.id">
                {{ propType.name }}
              </ng-option>
            </ng-select>
          </div>
        </div>

        <div class="row"><div class="col-lg-12"><hr class="hr-separator edit-separator"/></div></div>

        <div class="row">
          <label class="col-form-label col-sm-2" for="communication-{{uniqueId}}">{{'investments.editor.Communication' | translate}}</label>
          <div class="col-sm-4">
            <input type="text" attr.id="communication-{{uniqueId}}" name="communication" placeholder="communication" class="form-control" [(ngModel)]="investmentEdit.communication" />
          </div>
          <label class="col-form-label col-sm-2" for="roomsHeight-{{uniqueId}}">{{'investments.editor.RoomsHeight' | translate}}</label>
          <div class="col-sm-4">
            <input type="text" attr.id="roomsHeight-{{uniqueId}}" name="roomsHeight" placeholder="roomsHeight" class="form-control" [(ngModel)]="investmentEdit.roomsHeight" />
          </div>
        </div>

        <div class="row"><div class="col-lg-12"><hr class="hr-separator edit-separator"/></div></div>

        <div class="row">
          <label class="col-form-label col-sm-2" for="developerDesc-{{uniqueId}}">{{'investments.editor.DeveloperDesc' | translate}}</label>
          <div class="col-sm-10">
            <input type="text" attr.id="developerDesc-{{uniqueId}}" name="developerDesc" placeholder="developerDesc" class="form-control" [(ngModel)]="investmentEdit.developerDesc" />
          </div>
        </div>

        <div class="row"><div class="col-lg-12"><hr class="hr-separator edit-separator"/></div></div>

        <div class="row">
          <label class="col-form-label col-sm-2" for="description-{{uniqueId}}">{{'investments.editor.Description' | translate}}</label>
          <div class="col-sm-10">
            <textarea rows="4" attr.id="description-{{uniqueId}}" name="description" placeholder="description" class="form-control" [(ngModel)]="investmentEdit.description"></textarea>
          </div>
        </div>
      </div>

      <!--  Address  -->
      <div class="tab-pane fade" id="address-container" role="tabpanel" aria-labelledby="address-tab">
        <div class="row">
          <label class="col-form-label col-sm-1" for="addressTypeId-{{uniqueId}}">{{'investments.editor.AddressTypeId' | translate}}</label>
          <div class="col-sm-3">
            <ng-select id="addressTypeId-{{uniqueId}}" name="addressTypeId" [(ngModel)]="investmentEdit.address.addressTypeId" class="form-control">
              <ng-option *ngFor="let addrType of addressTypes" [value]="addrType.id">
                {{ addrType.name }}
              </ng-option>
            </ng-select>
          </div>
          <label class="col-form-label col-sm-1" for="city-{{uniqueId}}">{{'investments.editor.City' | translate}}</label>
          <div class="col-sm-3">
            <input type="text" attr.id="city-{{uniqueId}}" name="city" placeholder="city" class="form-control" [(ngModel)]="investmentEdit.address.city" />
          </div>
          <label class="col-form-label col-sm-1" for="district-{{uniqueId}}">{{'investments.editor.District' | translate}}</label>
          <div class="col-sm-3">
            <input type="text" attr.id="district-{{uniqueId}}" name="district" placeholder="district" class="form-control" [(ngModel)]="investmentEdit.address.district" />
          </div>
        </div>


        <div class="row"><div class="col-lg-12"><hr class="hr-separator edit-separator"/></div></div>

        <div class="row">
          <label class="col-form-label col-sm-1" for="street-{{uniqueId}}">{{'investments.editor.Street' | translate}}</label>
          <div class="col-sm-2">
            <input type="text" attr.id="street-{{uniqueId}}" name="street" placeholder="street" class="form-control" [(ngModel)]="investmentEdit.address.street" />
          </div>
          <label class="col-form-label col-sm-2 text-end" for="buildingNr-{{uniqueId}}">{{'investments.editor.BuildingNr' | translate}}</label>
          <div class="col-sm-1">
            <input type="text" attr.id="buildingNr-{{uniqueId}}" name="buildingNr" placeholder="buildingNr" class="form-control" [(ngModel)]="investmentEdit.address.buildingNr" />
          </div>
          <label class="col-form-label col-sm-2 text-end" for="apartmentNr-{{uniqueId}}">{{'investments.editor.ApartmentNr' | translate}}</label>
          <div class="col-sm-1">
            <input type="text" attr.id="apartmentNr-{{uniqueId}}" name="apartmentNr" placeholder="apartmentNr" class="form-control" [(ngModel)]="investmentEdit.address.apartmentNr" />
          </div>
        </div>

        <div class="row"><div class="col-lg-12"><hr class="hr-separator edit-separator"/></div></div>

        <div class="row">
          <label class="col-form-label col-sm-1" for="latitude-{{uniqueId}}">{{'investments.editor.Latitude' | translate}}</label>
          <div class="col-sm-5">
            <input type="text" attr.id="latitude-{{uniqueId}}" name="latitude" placeholder="latitude" class="form-control" [(ngModel)]="investmentEdit.address.latitude" [readonly]="true" />
          </div>
          <label class="col-form-label col-sm-1" for="longitude-{{uniqueId}}">{{'investments.editor.Longitude' | translate}}</label>
          <div class="col-sm-5">
            <input type="text" attr.id="longitude-{{uniqueId}}" name="longitude" placeholder="longitude" class="form-control" [(ngModel)]="investmentEdit.address.longitude" [readonly]="true" />
          </div>

        </div>

        <div class="row"><div class="col-lg-12"><hr class="hr-separator edit-separator"/></div></div>
        <div class="row">
          <label class="col-form-label col-sm-1" for="addressDescription-{{uniqueId}}">{{'investments.editor.AddressDescription' | translate}}</label>
          <div class="col-sm-11">
            <input type="text" attr.id="addressDescription-{{uniqueId}}" name="addressDescription" placeholder="addressDescription" class="form-control" [(ngModel)]="investmentEdit.address.description" />
          </div>
        </div>


      </div>

      <!--  Files  -->
      <div class="tab-pane fade" id="files-container" role="tabpanel" aria-labelledby="contact-tab">

        <div class="row"><h4>{{'investments.editor.Images' | translate}}</h4></div>

        <div *ngFor="let image of existingPictures" class="m-2 image-list-item">
          <div class="image-action-list">
            <i class="fa fa-window-close image-action-icon" (click)="deleteImage(image, ImageTypeEnum.PICTURE)"></i>
          </div>
          <img src="{{image.path}}" class="image-preview" alt="picture..." onmousedown="return false"/>
        </div>

        <div class="row mb-2">
          <div class=" col-sm-8">
            <div class="row">
              <label class="col-form-label col-sm-3 text-end" for="images-{{uniqueId}}">{{'investments.editor.AddNewPhoto' | translate}}</label>
              <div class="col-sm-9">
                <input #imageInput type="file" attr.id="images-{{uniqueId}}" name="images" class="form-control"
                  (change)="onImagesSelected(imageInput.files, ImageTypeEnum.PICTURE)" multiple accept=".jpg, .jpeg, .png"/>
              </div>
              <div *ngIf="selectedImages && selectedImages.length">
                <div *ngFor="let item of selectedImages" class="d-flex justify-content-between flex-wrap bg-light text-primary">
                  <div>{{ item.name }}</div>
                  <div>{{ humanFileSize(item.size) }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row"><div class="col-lg-12"><hr class="hr-separator edit-separator"/></div></div>

        <div class="row"><h4>{{'investments.editor.Plans' | translate}}</h4></div>

        <div *ngFor="let image of existingPlans" class="m-2 image-list-item">
          <div class="image-action-list">
            <i class="fa fa-window-close image-action-icon" (click)="deleteImage(image, ImageTypeEnum.FLOOR_PLAN)"></i>
          </div>
          <img src="{{image.path}}" class="image-preview" alt="plan..." onmousedown="return false"/>
        </div>

        <div class="row mb-2">
          <div class=" col-sm-8">
            <div class="row">
              <label class="col-form-label col-sm-3 text-end" for="plans-{{uniqueId}}">{{'investments.editor.AddNewPlan' | translate}}</label>
              <div class="col-sm-9">
                <input #planInput type="file" attr.id="plans-{{uniqueId}}" name="plans" class="form-control"
                  (change)="onImagesSelected(planInput.files, ImageTypeEnum.FLOOR_PLAN)" multiple accept=".jpg, .jpeg, .png, .pdf"/>
              </div>
              <div *ngIf="selectedPlans && selectedPlans.length">
                <div *ngFor="let item of selectedPlans" class="d-flex justify-content-between flex-wrap bg-light text-primary">
                  <div>{{ item.name }}</div>
                  <div>{{ humanFileSize(item.size) }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row"><div class="col-lg-12"><hr class="hr-separator edit-separator"/></div></div>

        <div class="row"><h4>{{'investments.editor.Docs' | translate}}</h4></div>

        <div *ngFor="let doc of existingDocs" class="m-2 image-list-item">
          <div class="document-block">
            <div class="document-action-list">
              <i class="fa fa-window-close image-action-icon" (click)="deleteDocument(doc)"></i>
            </div>
            <span><strong>{{ mapDocumentTypeIdToTypeName(doc.documentTypeId) }}</strong></span><br/>
            <span><a href="{{doc.path}}">{{ doc.name }}</a></span><br/>
            <span>{{ doc.path }}</span>
          </div>
        </div>


        <div class="row mb-2">
          <label class="col-form-label col-sm-1 text-end" for="documentTypeId-{{uniqueId}}">{{'investments.editor.DocumentTypeId' | translate}}</label>
          <div class="col-sm-3">
            <ng-select id="documentTypeId-{{uniqueId}}" name="documentTypeId" [(ngModel)]="documentTypeSelectedId" class="form-control">
              <ng-option *ngFor="let docType of documentTypes" [value]="docType.id">
                {{ docType.name }}
              </ng-option>
            </ng-select>
          </div>
          <div class=" col-sm-6">
            <div class="row">
              <label class="col-form-label col-sm-3" for="docs-{{uniqueId}}">{{'investments.editor.AddNewFile' | translate}}</label>
              <div class="col-sm-9">
                <input #docInput type="file" attr.id="docs-{{uniqueId}}" name="docs" class="form-control" (change)="onDocumentsSelected(docInput.files, documentTypeSelectedId)" multiple/>
              </div>
              <div *ngIf="selectedDocs && selectedDocs.length">
                <div *ngFor="let doc of selectedDocs" class="d-flex justify-content-between flex-wrap bg-light text-primary">
                  <div>{{ mapDocumentTypeIdToTypeName(doc.typeId) }}</div>
                  <div>{{ doc.file.name }}</div>
                  <div>{{humanFileSize(doc.file.size) }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--  Flats  -->
      <div class="tab-pane fade" id="flats-container" role="tabpanel" aria-labelledby="contact-tab">
        <ngx-datatable class="material colored-header sm table-x table-striped table-hover"
          [loadingIndicator]="loadingIndicator"
          [rows]="flatRows"
          [rowHeight]="35"
          [headerHeight]="37"
          [footerHeight]="35"
          [columns]="flatColumns"
          [scrollbarV]="true"
          [columnMode]="'force'">
        </ngx-datatable>
      </div>

    </div>

    <div class="row">
      <div class="col-sm-12">
        <div ngPreserveWhitespaces class="float-end">
          <button type="button" (click)="cancel()" class="btn btn-danger" [disabled]="isSavingInProgress"><i class='fa fa-times'></i> {{'users.editor.Cancel' | translate}}</button>
          <button type="submit" class="btn btn-primary" [disabled]="isSavingInProgress">
            <i *ngIf="!isSavingInProgress" class='fa fa-save'></i>
            <i *ngIf="isSavingInProgress" class='fa fa-circle-o-notch fa-spin'></i> {{isSavingInProgress ? ('investments.editor.Saving' | translate) : ('investments.editor.Save' | translate)}}
          </button>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>

  </form>
</div>
