<div>
  <div class="row control-box">
    <div class="col-lg-6">
      <div class="search-box">
        <app-search-box (searchChange)="onSearchChanged($event)" placeholder="{{'imports.management.Search' | translate}}"></app-search-box>
      </div>
    </div>
    <div class="col-lg-6 pe-lg-5">
      <ul class="nav flex-column flex-lg-row justify-content-end">
        <li class="nav-item toolbaritem">
          <a class="nav-link" href="javascript:;" (click)="invokeNewImport()">
            <i class="fa fa-plus-circle"></i> {{'imports.management.NewImport' | translate}}
          </a>
          <input #importInput type="file" accept=".xls, .xlsx" (change)="onFileSelected(importInput.files)" hidden/>
        </li>
        <li class="nav-item toolbaritem">
          <a class="nav-link" href="javascript:;" (click)="invokeFlatImportFromServer()">
            <i class="fa fa-server"></i> {{'imports.management.InvokeFlatImportFromServer' | translate}}
          </a>
        </li>
      </ul>
    </div>
  </div>

  <ngx-datatable class="material colored-header sm table-x table-striped table-hover"
                 [loadingIndicator]="loadingIndicator"
                 [rows]="gridRows"
                 [rowHeight]="35"
                 [headerHeight]="37"
                 [footerHeight]="35"
                 [columns]="gridColumns"
                 [scrollbarV]="true"
                 [columnMode]="'force'">
  </ngx-datatable>
</div>

<div class="modal fade" bsModal #detailsModal="bs-modal"[config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title float-start"><i class="fa fa-home"></i> {{'imports.editor.Title' | translate}}</h4>
        <button type="button" class="btn-close float-end fs-sm" title="Close" (click)="detailsModal.hide()"></button>
      </div>
      <div class="modal-body">
        <app-imports-details #importDetails></app-imports-details>
      </div>
    </div>
  </div>
</div>


<ng-template #actionsTemplate let-row="row" let-value="value" let-i="index">
  <div>
    <a class="btn btn-link text-link btn-sm" href="javascript:;" (click)="showDetails(row)">
      <i class="fa fa-info-circle" aria-hidden="true"></i> {{'imports.management.Details' | translate}}
    </a>
  </div>
</ng-template>

<ng-template #dateCellTemplate let-row="row" let-value="value">
  {{ formatDate(value) }}
</ng-template>

