export enum Gender {
    None,
    Female,
    Male
}

export enum ImageType {
    PICTURE = 1,
    FLOOR_PLAN = 2,
}

export enum DocumentType {
    SCHEDULE = 1,
    TEASER = 2,
    TECH_STANDARD = 3,
    DEVELOPER_AGREEMENT = 4,
}