import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ImageFilesWithType } from '../models/image-files-with-type.model';
import { FileEndpoint } from './file-endpoint.service';
import { DocumentFilesWithType } from '../models/document-files-with-type.model';

@Injectable()
export class FileService {

  constructor(
    private fileEndpoint: FileEndpoint) {
  }

  uploadImagesForInvestment(investmentId: number, imagesWithType: ImageFilesWithType[]) : Observable<number> {
    const formData = new FormData();
    imagesWithType.forEach(element => {
      if (element.images.length > 0) {
        this.appendFilesToFormData(formData, element.images, element.typeId.toString());
      }
    });
    return this.fileEndpoint.uploadImagesForInvestmentEndpoint<number>(investmentId, formData);
  }

  uploadImagesForFlat(flatId: number, imagesWithType: ImageFilesWithType[]) : Observable<number> {
    const formData = new FormData();
    imagesWithType.forEach(element => {
      if (element.images.length > 0) {
        this.appendFilesToFormData(formData, element.images, element.typeId.toString());
      }
    });
    return this.fileEndpoint.uploadImagesForFlatEndpoint<number>(flatId, formData);
  }


  deleteImageForInvestment(investmentImageId: number) : Observable<number>{
    return this.fileEndpoint.deleteImageForInvestmentEndpoint<number>(investmentImageId);
  }

  deleteImageForFlat(flatImageId: number) : Observable<number>{
    return this.fileEndpoint.deleteImageForFlatEndpoint<number>(flatImageId);
  }

  uploadDocumentsForInvestment(investmentId: number, docsWithType: DocumentFilesWithType[]) : Observable<number> {
    const formData = new FormData();
    docsWithType.forEach(element => {
      if (element.docs.length > 0) {
        this.appendFilesToFormData(formData, element.docs, element.typeId.toString());
      }
    });
    return this.fileEndpoint.getUploadDocumentsForInvestmentEndpoint<number>(investmentId, formData);
  }

  deleteDocumentForInvestment(investmentDocumentId: number) : Observable<number>{
    return this.fileEndpoint.getDeleteDocumentForInvestmentEndpoint<number>(investmentDocumentId);
  }


  private appendFilesToFormData(formData: FormData, files: File[], typeId: string): void {
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
      formData.append('typeIds', typeId);
    }
  }
}
