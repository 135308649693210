import { Component } from '@angular/core';
import { fadeInOut } from '../../services/animations';

@Component({
  selector: 'app-imports',
  templateUrl: './flats.component.html',
  styleUrls: ['./flats.component.scss'],
  animations: [fadeInOut]
})
export class FlatsComponent {
}
