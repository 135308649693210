import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { FlatEndpoint } from './flat-endpoint.service';
import { FlatGridDto } from '../models/flat-grid-dto.model';
import { FlatFullDto } from '../models/flat-full-dto.model';
import { FlatShortDto } from '../models/flat-short-dto.model';


@Injectable()
export class FlatService {

  constructor(
    private flatEndpoint: FlatEndpoint) {
  }

  getFlatsGrid(page?: number, pageSize?: number) : Observable<FlatGridDto[]> {
    return this.flatEndpoint.getFlatsGridEndpoint<FlatGridDto[]>();
  }

  getFlatById(flatId: number): Observable<FlatFullDto>{
    return this.flatEndpoint.getFlatByIdEndpoint<FlatFullDto>(flatId);
  }

  getFlatsForInvestment(investmentId: number) : Observable<FlatShortDto[]> {
    return this.flatEndpoint.getFlatsForInvestmentEndpoint<FlatShortDto[]>(investmentId);
  }

  addFlat(flat: FlatFullDto) : Observable<number> {
    return this.flatEndpoint.addFlatEndpoint<number>(flat);
  }

  updateFlat(flat: FlatFullDto) : Observable<number> {
    return this.flatEndpoint.updateFlatEndpoint<number>(flat);
  }
}
