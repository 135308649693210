import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AlertService, MessageSeverity } from 'src/app/services/alert.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { Utilities } from 'src/app/services/utilities';
import { ImportService } from 'src/app/services/import.service';
import { ImportFlatGridDto } from 'src/app/models/import-flat.model';
import { DatePipe } from '@angular/common';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ImportsDetailsComponent } from '../imports-details/imports-details.component';


@Component({
  selector: 'app-imports-management',
  templateUrl: './imports-management.component.html',
  styleUrls: ['./imports-management.component.scss']
})
export class ImportsManagementComponent implements OnInit {
  public gridColumns: any[] = [];
  public gridRows: ImportFlatGridDto[] = [];
  private rowsCache: ImportFlatGridDto[] = [];
  private gT = (key: string | Array<string>, interpolateParams?: object) => this.translationService.getTranslation(key, interpolateParams);
  public loadingIndicator: boolean;
  public datePipe: DatePipe = new DatePipe('en-US');

  @ViewChild('actionsTemplate', { static: true }) actionsTemplate: TemplateRef<any>;
  @ViewChild('importInput', { static: false }) importInput: ElementRef;
  @ViewChild('dateCellTemplate', { static: true })  dateCellTemplateRef: TemplateRef<any>;

  @ViewChild('detailsModal', { static: true })
  detailsModal: ModalDirective;
  @ViewChild('importDetails', { static: true })
  importDetails: ImportsDetailsComponent;

  constructor(private alertService: AlertService,
    private translationService: AppTranslationService,
    private importService: ImportService) {
  }

  ngOnInit() {
    this.gridColumns = [
      { prop: 'importFlatHistoryId', name: this.gT('imports.management.Id'), width: 20 },
      { prop: 'fileName', name: this.gT('imports.management.FileName'), width: 120 },
      { prop: 'importHistoryStageType', name: this.gT('imports.management.StageType'), width: 100 },
      { prop: 'added', name: this.gT('imports.management.Added'), width: 80 },
      { prop: 'updated', name: this.gT('imports.management.Updated'), width: 80 },
      { prop: 'processingTimeInSeconds', name: this.gT('imports.management.TotalTime'), width: 80 },
      { prop: 'fileSize', name: this.gT('imports.management.FileSize'), width: 80 },
      { prop: 'addDate', name: this.gT('imports.management.AddDate'), width: 100, cellTemplate: this.dateCellTemplateRef, },
      { prop: 'finishDate', name: this.gT('imports.management.FinishDate'), width: 100, cellTemplate: this.dateCellTemplateRef, },
    ];

    this.gridColumns.push({ name: '', width: 150, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false });

    this.loadData();
  }

  ngAfterViewInit() {

    this.importDetails.changesCancelledCallback = () => {
      this.detailsModal.hide();
    };
  }

  private loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.importService.getFlatHistoryGrid()
    .subscribe({
      next: flatImports => this.onDataLoadSuccessful(flatImports),
      error: error => this.onDataLoadFailed(error)
    });
  }
  public invokeNewImport(){
    this.importInput.nativeElement.click();
  }

  public invokeFlatImportFromServer(){
    this.importService.invokeFlatImportFromServer()
    .subscribe({
      next: flatsImportedSum => {
        this.loadData();
        this.alertService.showMessage(this.gT('imports.management.InvokeFlatImportFromServer'),
                this.gT('imports.management.InvokeFlatImportFromServerDesc', { sum: flatsImportedSum }), MessageSeverity.success);
      },
      error: (error: any) => this.errorHandle(error)
    });
  }

  public onFileSelected(files: FileList): void {
    this.importService.uploadFlatsForInvestments(files[0])
    .subscribe({
      next: _ => {
        this.loadData();
        this.alertService.showMessage(this.gT('imports.management.FileAddedTitle'), this.gT('imports.management.FileAddedDesc'), MessageSeverity.success);
      },
      error: (error: any) => this.errorHandle(error)
    });
  }

  private onDataLoadSuccessful(flatImports: ImportFlatGridDto[]): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.rowsCache = [...flatImports];
    this.gridRows = flatImports;
  }

  private onDataLoadFailed(error: any): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  public onSearchChanged(value: string): void {
    this.gridRows = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.fileName, r.importHistoryStageType));
  }

  public showDetails(flatImport: ImportFlatGridDto) {
    this.importDetails.showImportHistory(flatImport.importFlatHistoryId);
    this.detailsModal.show();
  }

  public formatDate(dateString: string): string {
    if (dateString.length !== 0) {
      const date = new Date(dateString);
      return this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
    }
    return "";
  }

  private errorHandle(error: any) {
    const errorSerialized = JSON.stringify(error, null, 2);
    this.alertService.showStickyMessage('Error', errorSerialized, MessageSeverity.error, error);
  }
}
