import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { EndpointBase } from './endpoint-base.service';
import { ConfigurationService } from './configuration.service';
import { FlatFullDto } from '../models/flat-full-dto.model';


@Injectable()
export class FlatEndpoint extends EndpointBase {

  get flatsUrl() { return this.configurations.baseUrl + '/api/flat'; }


  constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService) {
    super(http, authService);
  }

  getFlatsGridEndpoint<T>(page?: number, pageSize?: number): Observable<T> {
    const baseUrl = `${this.flatsUrl}/grid/`;
    const endpointUrl = page && pageSize ? `${baseUrl}/${page}/${pageSize}` : baseUrl;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getFlatsGridEndpoint(page, pageSize));
      }));
  }

  getFlatByIdEndpoint<T>(flatId: number): Observable<T> {
    const endpointUrl = `${this.flatsUrl}/${flatId}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getFlatByIdEndpoint(flatId));
      }));
  }

  getFlatsForInvestmentEndpoint<T>(investmentId: number): Observable<T> {
    const endpointUrl = `${this.flatsUrl}/inv/${investmentId}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getFlatsForInvestmentEndpoint(investmentId));
      }));
  }

  addFlatEndpoint<T>(flatObject: FlatFullDto): Observable<T> {
    const endpointUrl =  `${this.flatsUrl}/`;

    return this.http.post<T>(endpointUrl, JSON.stringify(flatObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.addFlatEndpoint(flatObject));
      }));
  }

  updateFlatEndpoint<T>(flatObject: FlatFullDto): Observable<T> {
    const endpointUrl =  `${this.flatsUrl}/`;

    return this.http.put<T>(endpointUrl, JSON.stringify(flatObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.updateFlatEndpoint(flatObject));
      }));
  }
}
