export class ImportFlatGridDto {
    importFlatHistoryId: number;
    fileName: string;
    importHistoryStageType: string;
    added: number;
    updated: number;
    processingTimeInSeconds: number;
    fileSize: string;
    addDate: Date;
    finishDate: Date;
  }

  export class ImportFlatHistoryFullDto {
    importFlatHistoryId: number;
    fileName: string;
    importHistoryStageType: string;
    added: number;
    updated: number;
    processingTimeInSeconds: number;
    fileSize: string;
    validationMessages: string;
    exceptions: string;
    addDate: Date;
    finishDate: Date;
  }