import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AlertService, MessageSeverity } from 'src/app/services/alert.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { Utilities } from 'src/app/services/utilities';
import { FlatService } from 'src/app/services/flat.service';
import { FlatGridDto } from 'src/app/models/flat-grid-dto.model';
import { DatePipe } from '@angular/common';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FlatEditorComponent } from '../flats-editor/flat-editor.component';

@Component({
  selector: 'app-flats-management',
  templateUrl: './flats-management.component.html',
  styleUrls: ['./flats-management.component.scss']
})
export class FlatsManagementComponent implements OnInit, AfterViewInit {
  public gridColumns: any[] = [];
  public gridRows: FlatGridDto[] = [];
  private rowsCache: FlatGridDto[] = [];
  private gT: (key: string) => any;
  public loadingIndicator: boolean;
  public datePipe: DatePipe = new DatePipe('en-US');
  public editingFlatNumber: { name: string };


  @ViewChild('dateCellTemplate', { static: true })  dateCellTemplateRef: TemplateRef<any>;
  @ViewChild('actionsTemplate', { static: true }) actionsTemplate: TemplateRef<any>;

  @ViewChild('editorModal', { static: true })
  editorModal: ModalDirective;
  @ViewChild('flatEditor', { static: true })
  flatEditor: FlatEditorComponent;



  constructor(private alertService: AlertService,
    private translationService: AppTranslationService,
    private flatService: FlatService) {
  }

  ngOnInit() {
    this.gT = (key: string) => this.translationService.getTranslation(key);

    this.gridColumns = [
      { prop: 'flatId', name: this.gT('flats.management.flatId'), width: 20 },
      { prop: 'investmentName', name: this.gT('flats.management.investmentName'), width: 120 },
      { prop: 'flatNumber', name: this.gT('flats.management.flatNumber'), width: 100 },
      { prop: 'developerCode', name: this.gT('flats.management.developerCode'), width: 80 },
      { prop: 'stageType', name: this.gT('flats.management.stageType'), width: 80 },
      { prop: 'statusType', name: this.gT('flats.management.statusType'), width: 80 },
      { prop: 'price', name: this.gT('flats.management.price'), width: 80 },
      { prop: 'currency', name: this.gT('flats.management.currency'), width: 80 },
      { prop: 'updatedDate', name: this.gT('flats.management.updatedDate'), width: 150, cellTemplate: this.dateCellTemplateRef, },
      { name: '', width: 150, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false },
    ];

    this.loadData();
  }

  private loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.flatService.getFlatsGrid()
    .subscribe({
      next: result => this.onDataLoadSuccessful(result),
      error: error => this.onDataLoadFailed(error)
    });
  }

  ngAfterViewInit() {
    this.flatEditor.changesSavedCallback = () => {
      this.loadData();
      this.editorModal.hide();
    };

    this.flatEditor.changesCancelledCallback = () => {
      this.editorModal.hide();
    };
  }

  public formatDate(dateString: string): string {
    if (dateString.length !== 0) {
      const date = new Date(dateString);
      return this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
    }
    return "";
  }

  public newFlat() : void {
    this.editingFlatNumber = null;
    this.flatEditor.newFlat();
    this.editorModal.show();
  }

  private onDataLoadSuccessful(flatGrid: FlatGridDto[]): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.rowsCache = [...flatGrid];
    this.gridRows = flatGrid;
  }

  private onDataLoadFailed(error: any): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  public onSearchChanged(value: string): void {
    this.gridRows = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.flatNumber, r.developerCode));
  }

  public editFlat(flatGridDto: FlatGridDto) {
    this.editingFlatNumber = { name: flatGridDto.flatNumber };
    this.flatEditor.editFlat(flatGridDto.flatId);
    this.editorModal.show();
  }

  public onEditorModalHidden() {
    this.editingFlatNumber = null;
    this.flatEditor.resetForm(true);
  }
}
