import { FlatImageDto } from "./flat-image-dto.model";

export class FlatFullDto {
    flatId: number;
    investmentId: number | null;
    flatNumber: string;
    developerCode: string;
    price: number;
    pricePromo: number | null;
    priceMeter: number;
    currency: string;
    squareArea: number | null;
    squareAreaUnit: string | null;
    floor: number | null;
    roomCount: number | null;
    isBrightKitchen: boolean | null;
    isTwoSides: boolean | null;
    parkingDesc: string | null;
    storageRoomDesc: string | null;
    isLift: boolean | null;
    distanceFromCenter: string | null;
    description: string | null;
    isPromotion: boolean;
    promotionDesc: string | null;
    propertyTypeId: number | null;
    stage: number | null;
    statusTypeId: number | null;
    completionTermTypeId: number | null;
    worldDirectionTypeId: number | null;
    surroundingTypeIds: number[] | null;
    parkingTypeIds: number[] | null;
    flatAdvantageTypeIds: number[] | null;
    promotionTypeIds: number[] | null;

    images: FlatImageDto[] | null;
}
