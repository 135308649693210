<div class="d-flex h-90">
  <div class="recover-container m-auto">
    <div class="card boxshadow">
      <div class="card-header bg-primary text-white">
        <span>
          <i class="fa fa-question"></i>
          <i class="fa fa-lock overlay-icon-lower-right"></i>
        </span>
        {{'recoverPassword.RecoverPassword' | translate}}
      </div>
      <div class="card-body">
        <div>
          <form *ngIf="formResetToggle" class="recover" name="recoverForm" #f="ngForm" novalidate
                (ngSubmit)="f.form.valid ? recoverPassword() : showErrorAlert('recoverPassword.alerts.UsernameOrEmailRequired', 'recoverPassword.alerts.EnterValidUsernameOrEmail')">
            <div>
              <p *ngIf="!isSuccess" class="form-control-plaintext">{{'recoverPassword.EnterUsernameOrEmail' | translate}}</p>
              <p *ngIf="isSuccess" class="form-control-plaintext">{{'recoverPassword.CheckYourMail' | translate}}</p>
            </div>
            <div *ngIf="!isSuccess" class="mb-3 row">
              <div class="col-md-12">
                <input type="text" id="recover-username" name="username" placeholder="{{'recoverPassword.UsernameOrEmailPlaceholder' | translate}}" class="form-control" [ngClass]="{'is-valid': f.submitted && username.valid, 'is-invalid' : f.submitted && !username.valid}"
                       [(ngModel)]="usernameOrEmail" #username="ngModel" required />
                <span class="invalid-feedback" *ngIf="f.submitted && !username.valid">
                  {{'recoverPassword.UsernameOrEmailRequired' | translate}}
                </span>
              </div>
            </div>
            <div class="mb-3 row">
              <div class="col-md-12">
                <div ngPreserveWhitespaces class="float-end">
                  <button *ngIf="isSuccess" type="button" routerLink="/" class="btn btn-outline-success">{{'recoverPassword.Finish' | translate}}</button>
                  <button *ngIf="!isSuccess" type="button" routerLink="/" class="btn btn-outline-danger" [disabled]="isLoading"><i class='fa fa-times'></i> {{'recoverPassword.Cancel' | translate}}</button>
                  <button *ngIf="!isSuccess" type="submit" class="btn btn-primary" [disabled]="isLoading">
                    <i *ngIf="!isLoading" class='fa fa-send'></i><i *ngIf="isLoading" class='fa fa-circle-o-notch fa-spin'></i> {{isLoading ? ('recoverPassword.Processing' | translate) : ('recoverPassword.Send' | translate)}}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
