import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService, DialogType, MessageSeverity } from 'src/app/services/alert.service';
import { Permission } from 'src/app/models/permission.model';
import { AccountService } from 'src/app/services/account.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { Utilities } from 'src/app/services/utilities';
import { InvestmentService } from 'src/app/services/investment.service';
import { InvestmentGridDto } from 'src/app/models/investment-grid-dto.model';
import { InvestmentEditorComponent } from '../investments-editor/investment-editor.component';
import { DatePipe } from '@angular/common';
import { FlatEditorComponent } from '../../flats/flats-editor/flat-editor.component';


@Component({
  selector: 'app-investments-management',
  templateUrl: './investments-management.component.html',
  styleUrls: ['./investments-management.component.scss']
})
export class InvestmentsManagementComponent implements OnInit, AfterViewInit {
  public gridColumns: any[] = [];
  public gridRows: InvestmentGridDto[] = [];
  private rowsCache: InvestmentGridDto[] = [];
  private gT: (key: string) => any;

  public editingInvestmentName: { name: string };
  public loadingIndicator: boolean;
  public datePipe: DatePipe = new DatePipe('en-US');

  @ViewChild('dateCellTemplate', { static: true })
  dateCellTemplateRef: TemplateRef<any>;

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;

  @ViewChild('investmentEditorModal', { static: true })
  investmentEditorModal: ModalDirective;

  @ViewChild('investmentEditor', { static: true })
  investmentEditor: InvestmentEditorComponent;


  @ViewChild('flatEditorModal', { static: true })
  flatEditorModal: ModalDirective;
  @ViewChild('flatEditor', { static: true })
  flatEditor: FlatEditorComponent;

  constructor(private alertService: AlertService,
    private translationService: AppTranslationService,
    private accountService: AccountService,
    private investmentService: InvestmentService) {
  }

  ngOnInit() {
    this.gT = (key: string) => this.translationService.getTranslation(key);

    this.gridColumns = [
      { prop: 'id', name: this.gT('investments.management.Id'), width: 50 },
      //{ prop: 'userName', name: this.gT('users.management.UserName'), width: 90, cellTemplate: this.userNameTemplate },
      { prop: 'investmentName', name: this.gT('investments.management.InvestmentName'), width: 120 },
      { prop: 'developerName', name: this.gT('investments.management.DeveloperName'), width: 120 },
      { prop: 'location', name: this.gT('investments.management.Location'), width: 150 },
      { prop: 'priceRange', name: this.gT('investments.management.Price'), width: 150 },
      { prop: 'latitude', name: this.gT('investments.management.Latitude'), width: 80 },
      { prop: 'longitude', name: this.gT('investments.management.Longitude'), width: 80 },
      { prop: 'createdDate', name: this.gT('investments.management.CreatedDate'), width: 100, cellTemplate: this.dateCellTemplateRef, },
    ];

    if (this.canManageInvestments) {
      this.gridColumns.push({ name: '', width: 160, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false });
    }

    this.loadData();
  }

  ngAfterViewInit() {
    this.investmentEditor.changesSavedCallback = () => {
      this.loadData();
      this.investmentEditorModal.hide();
    };

    this.investmentEditor.changesCancelledCallback = () => {
      this.investmentEditorModal.hide();
    };

    this.flatEditor.changesSavedCallback = () => {
      this.flatEditorModal.hide();
    };

    this.flatEditor.changesCancelledCallback = () => {
      this.flatEditorModal.hide();
    };
  }

  private loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.investmentService.getInvestmentsGrid()
    .subscribe({
      next: investments => this.onDataLoadSuccessful(investments),
      error: error => this.onDataLoadFailed(error)
    });
  }

  private onDataLoadSuccessful(investments: InvestmentGridDto[]): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.rowsCache = [...investments];
    this.gridRows = investments;
  }

  private onDataLoadFailed(error: any): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve investments from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  public onSearchChanged(value: string): void {
    this.gridRows = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.investmentName, r.developerName, r.location));
  }

  onEditorModalHidden() {
    this.editingInvestmentName = null;
    this.investmentEditor.resetForm(true);
  }

  onFlatEditorModalHidden() {
    this.flatEditor.resetForm(true);
  }

  public formatDate(dateString: string): string {
    if (dateString.length !== 0) {
      const date = new Date(dateString);
      return this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
    }
    return "";
  }

  public newInvestment() : void {
    this.editingInvestmentName = null;
    this.investmentEditor.newInvestment();
    this.investmentEditorModal.show();
  }

  public editInvestment(investment: InvestmentGridDto) {
    this.editingInvestmentName = { name: investment.investmentName };
    this.investmentEditor.editInvestment(investment.id);
    this.investmentEditorModal.show();
  }

  public addFlat(investmentId: number) {
    this.flatEditor.newFlat(investmentId);
    this.flatEditorModal.show();
    // this.alertService.showDialog(this.gT('investments.management.DeleteInvestmentQuestion'), DialogType.confirm, () => this.deleteInvestmentHelper(investmentDto));
  }

  private deleteInvestmentHelper(investmentDto: InvestmentGridDto) {
    this.alertService.startLoadingMessage(this.gT('investments.management.DeleteInvestmentInProgress'));
    this.loadingIndicator = true;
    this.investmentService.deleteInvestment(investmentDto.id)
    .subscribe({
      next: _ => {
        this.alertService.showMessage(this.gT('investments.management.DeleteInvestmentTitle'), this.gT('investments.management.DeleteInvestmentSuccess'), MessageSeverity.success);
        this.loadData();
      },
      error: error => this.onDataLoadFailed(error)
    });

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }

  get canManageInvestments() {
    return this.accountService.userHasPermission(Permission.manageInvestmentsPermission);
  }
}
