<div>

  <form *ngIf="formResetToggle" autocomplete="off" name="flatForm" #f="ngForm" novalidate
        (ngSubmit)="save()">


    <ul class="nav nav-tabs mb-3" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link active" id="data-tab" data-bs-toggle="tab" data-bs-target="#data-container" type="button" role="tab" aria-controls="data" aria-selected="true">
          {{'flats.editor.DataTab' | translate}}</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="files-tab" data-bs-toggle="tab" data-bs-target="#files-container" type="button" role="tab" aria-controls="files" aria-selected="false">
          {{'flats.editor.FilesTab' | translate}}</button>
      </li>
      <li class="nav-item ms-auto">
        <button type="submit" class="btn btn-primary" [disabled]="isSavingInProgress">
          <i *ngIf="!isSavingInProgress" class='fa fa-save'></i>
          <i *ngIf="isSavingInProgress" class='fa fa-circle-o-notch fa-spin'></i> {{isSavingInProgress ? ('flats.editor.Saving' | translate) : ('flats.editor.Save' | translate)}}
        </button>
      </li>
    </ul>

    <div class="tab-content mb-3" id="flatTabContent">

      <!--  Data  -->
      <div class="tab-pane fade show active" id="data-container" role="tabpanel" aria-labelledby="data-tab">

        <div class="row">
          <label class="col-form-label col-sm-1 text-end" for="flatNumber-{{uniqueId}}">{{'flats.editor.flatNumber' | translate}}</label>
          <div class="col-sm-2">
            <input type="text" required attr.id="flatNumber-{{uniqueId}}" name="flatNumber" placeholder="flatNumber" class="form-control" [(ngModel)]="flatEdit.flatNumber" />
          </div>

          <label class="col-form-label col-sm-2 text-end" for="developerCode-{{uniqueId}}">{{'flats.editor.developerCode' | translate}}</label>
          <div class="col-sm-2">
            <input type="text" required attr.id="developerCode-{{uniqueId}}" name="developerCode" placeholder="developerCode" class="form-control" [(ngModel)]="flatEdit.developerCode" />
          </div>

          <label class="col-form-label col-sm-2 text-end" for="investmentId-{{uniqueId}}">{{'flats.editor.investmentId' | translate}}</label>
          <div class="col-sm-3">
            <ng-select id="investmentId-{{uniqueId}}" required name="investmentId" [(ngModel)]="flatEdit.investmentId" class="form-control" placeholder="investmentId">
              <ng-option *ngFor="let investment of investments" [value]="investment.investmentId">
                {{ investment.investmentName }}
              </ng-option>
            </ng-select>
          </div>
        </div>

        <div class="row"><div class="col-lg-12"><hr class="hr-separator edit-separator"/></div></div>

        <div class="row">
          <label class="col-form-label col-sm-1 text-end" for="price-{{uniqueId}}">{{'flats.editor.price' | translate}}</label>
          <div class="col-sm-2">
            <input type="number" required attr.id="price-{{uniqueId}}" name="price" placeholder="price" class="form-control" [(ngModel)]="flatEdit.price" />
          </div>

          <label class="col-form-label col-sm-2 text-end" for="pricePromo-{{uniqueId}}">{{'flats.editor.pricePromo' | translate}}</label>
          <div class="col-sm-2">
            <input type="number" attr.id="pricePromo-{{uniqueId}}" name="pricePromo" placeholder="pricePromo" class="form-control" [(ngModel)]="flatEdit.pricePromo" />
          </div>

          <label class="col-form-label col-sm-2 text-end" for="priceMeter-{{uniqueId}}">{{'flats.editor.priceMeter' | translate}}</label>
          <div class="col-sm-2">
            <input type="number" required attr.id="priceMeter-{{uniqueId}}" name="priceMeter" placeholder="priceMeter" class="form-control" [(ngModel)]="flatEdit.priceMeter" />
          </div>
        </div>

        <div class="row"><div class="col-lg-12"><hr class="hr-separator edit-separator"/></div></div>

        <div class="row">
          <label class="col-form-label col-sm-1 text-end" for="currency-{{uniqueId}}">{{'flats.editor.currency' | translate}}</label>
          <div class="col-sm-2">
            <input type="text" required attr.id="currency-{{uniqueId}}" name="currency" placeholder="currency" class="form-control" [(ngModel)]="flatEdit.currency" />
          </div>

          <label class="col-form-label col-sm-2 text-end" for="squareArea-{{uniqueId}}">{{'flats.editor.squareArea' | translate}}</label>
          <div class="col-sm-2">
            <input type="number" attr.id="squareArea-{{uniqueId}}" name="squareArea" placeholder="squareArea" class="form-control" [(ngModel)]="flatEdit.squareArea" />
          </div>

          <label class="col-form-label col-sm-2 text-end" for="squareAreaUnit-{{uniqueId}}">{{'flats.editor.squareAreaUnit' | translate}}</label>
          <div class="col-sm-2">
            <input type="text" attr.id="squareAreaUnit-{{uniqueId}}" name="squareAreaUnit" placeholder="squareAreaUnit" class="form-control" [(ngModel)]="flatEdit.squareAreaUnit" />
          </div>
        </div>

        <div class="row"><div class="col-lg-12"><hr class="hr-separator edit-separator"/></div></div>

        <div class="row">
          <label class="col-form-label col-sm-1 text-end" for="floor-{{uniqueId}}">{{'flats.editor.floor' | translate}}</label>
          <div class="col-sm-2">
            <input type="number" attr.id="floor-{{uniqueId}}" name="floor" placeholder="floor" class="form-control" [(ngModel)]="flatEdit.floor" />
          </div>

          <label class="col-form-label col-sm-2 text-end" for="roomCount-{{uniqueId}}">{{'flats.editor.roomCount' | translate}}</label>
          <div class="col-sm-2">
            <input type="number" attr.id="roomCount-{{uniqueId}}" name="roomCount" placeholder="roomCount" class="form-control" [(ngModel)]="flatEdit.roomCount" />
          </div>

          <label class="col-form-label col-sm-2 text-end" for="distanceFromCenter-{{uniqueId}}">{{'flats.editor.distanceFromCenter' | translate}}</label>
          <div class="col-sm-2">
            <input type="text" attr.id="distanceFromCenter-{{uniqueId}}" name="distanceFromCenter" placeholder="distanceFromCenter" class="form-control" [(ngModel)]="flatEdit.distanceFromCenter" />
          </div>
        </div>

        <div class="row"><div class="col-lg-12"><hr class="hr-separator edit-separator"/></div></div>

        <div class="row">
          <label class="col-form-label col-sm-2 text-end" for="parkingDesc-{{uniqueId}}">{{'flats.editor.parkingDesc' | translate}}</label>
          <div class="col-sm-10">
            <input type="text" attr.id="parkingDesc-{{uniqueId}}" name="parkingDesc" placeholder="parkingDesc" class="form-control" [(ngModel)]="flatEdit.parkingDesc" />
          </div>
        </div>

        <div class="row"><div class="col-lg-12"><hr class="hr-separator edit-separator"/></div></div>

        <div class="row">
          <label class="col-form-label col-sm-2 text-end" for="storageRoomDesc-{{uniqueId}}">{{'flats.editor.storageRoomDesc' | translate}}</label>
          <div class="col-sm-10">
            <input type="text" attr.id="storageRoomDesc-{{uniqueId}}" name="storageRoomDesc" placeholder="storageRoomDesc" class="form-control" [(ngModel)]="flatEdit.storageRoomDesc" />
          </div>
        </div>

        <div class="row"><div class="col-lg-12"><hr class="hr-separator edit-separator"/></div></div>

        <div class="row">
          <label class="col-form-label col-sm-1 text-end" for="isLift-{{uniqueId}}">{{'flats.editor.isLift' | translate}}</label>
          <div class="col-sm-1">
            <div class="form-check form-switch fs-5 pt-sm-1">
              <input type="checkbox" attr.id="isLift-{{uniqueId}}" name="isLift" placeholder="isLift" class="form-check-input" [(ngModel)]="flatEdit.isLift" />
            </div>
          </div>

          <label class="col-form-label col-sm-1 text-end" for="isTwoSides-{{uniqueId}}">{{'flats.editor.isTwoSides' | translate}}</label>
          <div class="col-sm-1">
            <div class="form-check form-switch fs-5 pt-sm-1">
              <input type="checkbox" attr.id="isTwoSides-{{uniqueId}}" name="isTwoSides" placeholder="isTwoSides" class="form-check-input" [(ngModel)]="flatEdit.isTwoSides" />
            </div>
          </div>

          <label class="col-form-label col-sm-1 text-end" for="isPromotion-{{uniqueId}}">{{'flats.editor.isPromotion' | translate}}</label>
          <div class="col-sm-1">
            <div class="form-check form-switch fs-5 pt-sm-1">
              <input type="checkbox" attr.id="isPromotion-{{uniqueId}}" name="isPromotion" placeholder="isPromotion" class="form-check-input" [(ngModel)]="flatEdit.isPromotion" />
            </div>
          </div>

          <label class="col-form-label col-sm-2 text-end" for="isBrightKitchen-{{uniqueId}}">{{'flats.editor.isBrightKitchen' | translate}}</label>
          <div class="col-sm-1">
            <div class="form-check form-switch fs-5 pt-sm-1">
              <input type="checkbox" attr.id="isBrightKitchen-{{uniqueId}}" name="isBrightKitchen" placeholder="isBrightKitchen" class="form-check-input" [(ngModel)]="flatEdit.isBrightKitchen" />
            </div>
          </div>
        </div>

        <div class="row"><div class="col-lg-12"><hr class="hr-separator edit-separator"/></div></div>

        <div class="row">
          <label class="col-form-label col-sm-2 text-end" for="promotionDesc-{{uniqueId}}">{{'flats.editor.promotionDesc' | translate}}</label>
          <div class="col-sm-10">
            <input type="text" attr.id="promotionDesc-{{uniqueId}}" name="promotionDesc" placeholder="promotionDesc" class="form-control" [(ngModel)]="flatEdit.promotionDesc" />
          </div>
        </div>

        <div class="row"><div class="col-lg-12"><hr class="hr-separator edit-separator"/></div></div>

        <div class="row">
          <label class="col-form-label col-sm-2 text-end" for="propertyTypeId-{{uniqueId}}">{{'flats.editor.propertyTypeId' | translate}}</label>
          <div class="col-sm-4">
            <ng-select id="propertyTypeId-{{uniqueId}}" name="propertyTypeId" [(ngModel)]="flatEdit.propertyTypeId" placeholder="propertyTypeId" class="form-control">
              <ng-option *ngFor="let propType of propertyTypes" [value]="propType.id">
                {{ propType.name }}
              </ng-option>
            </ng-select>
          </div>

          <label class="col-form-label col-sm-2 text-end" for="stage-{{uniqueId}}">{{'flats.editor.stage' | translate}}</label>
          <div class="col-sm-4">
            <input type="text" attr.id="stage-{{uniqueId}}" name="stage" placeholder="stage" class="form-control" [(ngModel)]="flatEdit.stage" />
          </div>
          <!-- <div class="col-sm-4">
            <ng-select id="stage-{{uniqueId}}" required name="stage" [(ngModel)]="flatEdit.stage" placeholder="stage" class="form-control">
              <ng-option *ngFor="let type of stageTypes" [value]="type.id">
                {{ type.name }}
              </ng-option>
            </ng-select>
          </div> -->
        </div>

        <div class="row"><div class="col-lg-12"><hr class="hr-separator edit-separator"/></div></div>

        <div class="row">
          <label class="col-form-label col-sm-2 text-end" for="statusTypeId-{{uniqueId}}">{{'flats.editor.statusTypeId' | translate}}</label>
          <div class="col-sm-4">
            <ng-select id="statusTypeId-{{uniqueId}}" name="statusTypeId" [(ngModel)]="flatEdit.statusTypeId" placeholder="statusTypeId" class="form-control">
              <ng-option *ngFor="let type of statusTypes" [value]="type.id">
                {{ type.name }}
              </ng-option>
            </ng-select>
          </div>

          <label class="col-form-label col-sm-2 text-end" for="completionTermTypeId-{{uniqueId}}">{{'flats.editor.completionTermTypeId' | translate}}</label>
          <div class="col-sm-4">
            <ng-select id="completionTermTypeId-{{uniqueId}}" name="completionTermTypeId" [(ngModel)]="flatEdit.completionTermTypeId" placeholder="completionTermTypeId" class="form-control">
              <ng-option *ngFor="let type of completionTermTypes" [value]="type.id">
                {{ type.name }}
              </ng-option>
            </ng-select>
          </div>
        </div>

        <div class="row"><div class="col-lg-12"><hr class="hr-separator edit-separator"/></div></div>

        <div class="row">
          <label class="col-form-label col-sm-2 text-end" for="worldDirectionTypeId-{{uniqueId}}">{{'flats.editor.worldDirectionTypeId' | translate}}</label>
          <div class="col-sm-4">
            <ng-select id="worldDirectionTypeId-{{uniqueId}}" name="worldDirectionTypeId" [(ngModel)]="flatEdit.worldDirectionTypeId" placeholder="worldDirectionTypeId" class="form-control">
              <ng-option *ngFor="let type of worldDirectionTypes" [value]="type.id">
                {{ type.name }}
              </ng-option>
            </ng-select>
          </div>

          <label class="col-form-label col-sm-2 text-end" for="promotionTypeIds-{{uniqueId}}">{{'flats.editor.promotionTypeIds' | translate}}</label>
          <div class="col-sm-4">
            <ng-select id="promotionTypeIds-{{uniqueId}}" name="promotionTypeIds" [multiple]="true" [(ngModel)]="flatEdit.promotionTypeIds" class="form-control" placeholder="promotionTypeIds">
              <ng-option *ngFor="let type of promotionTypes" [value]="type.id">
                {{ type.name }}
              </ng-option>
            </ng-select>
          </div>
        </div>


        <div class="row"><div class="col-lg-12"><hr class="hr-separator edit-separator"/></div></div>

        <div class="row">
          <label class="col-form-label col-sm-2 text-end" for="surroundingTypeIds-{{uniqueId}}">{{'flats.editor.surroundingTypeIds' | translate}}</label>
          <div class="col-sm-10">
            <ng-select id="surroundingTypeIds-{{uniqueId}}" name="surroundingTypeIds" [multiple]="true" [(ngModel)]="flatEdit.surroundingTypeIds" class="form-control" placeholder="surroundingTypeIds">
              <ng-option *ngFor="let type of surroundingTypes" [value]="type.id">
                {{ type.name }}
              </ng-option>
            </ng-select>
          </div>
        </div>

        <div class="row"><div class="col-lg-12"><hr class="hr-separator edit-separator"/></div></div>

        <div class="row">
          <label class="col-form-label col-sm-2 text-end" for="parkingTypeIds-{{uniqueId}}">{{'flats.editor.parkingTypeIds' | translate}}</label>
          <div class="col-sm-10">
            <ng-select id="parkingTypeIds-{{uniqueId}}" name="parkingTypeIds" [multiple]="true" [(ngModel)]="flatEdit.parkingTypeIds" class="form-control" placeholder="parkingTypeIds">
              <ng-option *ngFor="let type of parkingTypes" [value]="type.id">
                {{ type.name }}
              </ng-option>
            </ng-select>
          </div>
        </div>

        <div class="row"><div class="col-lg-12"><hr class="hr-separator edit-separator"/></div></div>

        <div class="row">
          <label class="col-form-label col-sm-2 text-end" for="flatAdvantageTypeIds-{{uniqueId}}">{{'flats.editor.flatAdvantageTypeIds' | translate}}</label>
          <div class="col-sm-10">
            <ng-select id="flatAdvantageTypeIds-{{uniqueId}}" name="flatAdvantageTypeIds" [multiple]="true" [(ngModel)]="flatEdit.flatAdvantageTypeIds" class="form-control" placeholder="flatAdvantageTypeIds">
              <ng-option *ngFor="let type of flatAdvantageTypes" [value]="type.id">
                {{ type.name }}
              </ng-option>
            </ng-select>
          </div>
        </div>

        <div class="row"><div class="col-lg-12"><hr class="hr-separator edit-separator"/></div></div>

        <div class="row">
          <label class="col-form-label col-sm-2 text-end" for="description-{{uniqueId}}">{{'flats.editor.description' | translate}}</label>
          <div class="col-sm-10">
            <textarea rows="4" attr.id="description-{{uniqueId}}" name="description" placeholder="description" class="form-control" [(ngModel)]="flatEdit.description"></textarea>
          </div>
        </div>

      </div>

      <!--  Files  -->
      <div class="tab-pane fade" id="files-container" role="tabpanel" aria-labelledby="contact-tab">

        <div class="row"><h4>{{'flats.editor.Plans' | translate}}</h4></div>

        <div *ngFor="let image of existingPlans" class="m-2 image-list-item">
          <div class="image-action-list">
            <i class="fa fa-window-close image-action-icon" (click)="deleteImage(image, ImageTypeEnum.FLOOR_PLAN)"></i>
          </div>
          <img src="{{image.path}}" class="image-preview" alt="plan..." onmousedown="return false"/>
        </div>

        <div class="row mb-2">
          <div class=" col-sm-6">
            <div class="row">
              <label class="col-form-label col-sm-3 text-end" for="plans-{{uniqueId}}">{{'flats.editor.AddNewPlan' | translate}}</label>
              <div class="col-sm-9">
                <input #planInput type="file" attr.id="plans-{{uniqueId}}" name="plans" class="form-control"
                  (change)="onImagesSelected(planInput.files, ImageTypeEnum.FLOOR_PLAN)" multiple accept=".jpg, .jpeg, .png, .pdf"/>
              </div>
              <div *ngIf="selectedPlans && selectedPlans.length">
                <div *ngFor="let item of selectedPlans" class="d-flex justify-content-between flex-wrap bg-light text-primary">
                  <div>{{ item.name }}</div>
                  <div>{{ humanFileSize(item.size) }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>

    <div class="row">
      <div class="col-sm-12">
        <div ngPreserveWhitespaces class="float-end">
          <button type="button" (click)="cancel()" class="btn btn-danger" [disabled]="isSavingInProgress"><i class='fa fa-times'></i> {{'users.editor.Cancel' | translate}}</button>
          <button type="submit" class="btn btn-primary" [disabled]="isSavingInProgress">
            <i *ngIf="!isSavingInProgress" class='fa fa-save'></i>
            <i *ngIf="isSavingInProgress" class='fa fa-circle-o-notch fa-spin'></i> {{isSavingInProgress ? ('flats.editor.Saving' | translate) : ('flats.editor.Save' | translate)}}
          </button>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>

  </form>
</div>
