import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { EndpointBase } from './endpoint-base.service';
import { ConfigurationService } from './configuration.service';

@Injectable()
export class FileEndpoint extends EndpointBase {

  get filesUrl() { return this.configurations.baseUrl + '/api/file'; }


  constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService) {
    super(http, authService);
  }

  uploadImagesForInvestmentEndpoint<T>(investmentId: number, formData: FormData): Observable<T> {
    const endpointUrl =  `${this.filesUrl}/images/investments/${investmentId}`;

    return this.http.post<T>(endpointUrl, formData, this.requestHeadersForFormData).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.uploadImagesForInvestmentEndpoint(investmentId, formData));
      }));
  }

  uploadImagesForFlatEndpoint<T>(flatId: number, formData: FormData): Observable<T> {
    const endpointUrl =  `${this.filesUrl}/images/flats/${flatId}`;

    return this.http.post<T>(endpointUrl, formData, this.requestHeadersForFormData).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.uploadImagesForFlatEndpoint(flatId, formData));
      }));
  }

  deleteImageForInvestmentEndpoint<T>(investmentImageId: number): Observable<T> {
    const endpointUrl =  `${this.filesUrl}/images/investments/${investmentImageId}`;

    return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.deleteImageForInvestmentEndpoint(investmentImageId));
      }));
  }

  deleteImageForFlatEndpoint<T>(flatImageId: number): Observable<T> {
    const endpointUrl =  `${this.filesUrl}/images/flats/${flatImageId}`;

    return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.deleteImageForFlatEndpoint(flatImageId));
      }));
  }

  getUploadDocumentsForInvestmentEndpoint<T>(investmentId: number, formData: FormData): Observable<T> {
    const endpointUrl =  `${this.filesUrl}/docs/${investmentId}`;

    return this.http.post<T>(endpointUrl, formData, this.requestHeadersForFormData).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUploadDocumentsForInvestmentEndpoint(investmentId, formData));
      }));
  }

  getDeleteDocumentForInvestmentEndpoint<T>(investmentDocumentId: number): Observable<T> {
    const endpointUrl =  `${this.filesUrl}/docs/${investmentDocumentId}`;

    return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDeleteDocumentForInvestmentEndpoint(investmentDocumentId));
      }));
  }


}
