<div class="d-flex h-95">
  <div class="login-container m-auto">
    <div class="card boxshadow">
      <div class="card-header bg-primary text-white">
        <i class="fa fa-lock" aria-hidden="true"></i> {{'login.Login' | translate}}
        <button *ngIf="isModal" type="button" class="btn-close btn-close-white fs-sm float-end" aria-label="Close" (click)="closeModal()"></button>
      </div>
      <div class="card-body">
        <div class="col-md-10 offset-md-1 px-md-3">
          <form *ngIf="formResetToggle" class="login" name="loginForm" #f="ngForm" novalidate
                (ngSubmit)="f.form.valid ? login() :
                          (!username.valid && showErrorAlert('login.alerts.UsernameRequired', 'login.alerts.EnterValidUsername'));
                          (!password.valid && showErrorAlert('login.alerts.PasswordRequired', 'login.alerts.EnterValidPassword'))">
            <div class="mb-3 row">
              <label class="col-form-label text-md-end col-md-4" for="login-username">{{'login.Username' | translate}}</label>
              <div class="col-md-8">
                <input type="text" id="login-username" name="username" placeholder="{{'login.UsernamePlaceholder' | translate}}" autocomplete="username" class="form-control" [ngClass]="{'is-valid': f.submitted && username.valid, 'is-invalid' : f.submitted && !username.valid}"
                       [(ngModel)]="userLogin.userName" #username="ngModel" required />
                <span class="invalid-feedback" *ngIf="f.submitted && !username.valid">
                  {{'login.UsernameRequired' | translate}}
                </span>
              </div>
            </div>
            <div class="mb-3 row">
              <label class="col-form-label text-md-end col-md-4" for="login-password">{{'login.Password' | translate}}</label>
              <div class="col-md-8">
                <input type="password" id="login-password" name="password" placeholder="{{'login.PasswordPlaceholder' | translate}}" autocomplete="current-password" class="form-control" [ngClass]="{'is-valid': f.submitted && password.valid, 'is-invalid' : f.submitted && !password.valid}"
                       [(ngModel)]="userLogin.password" #password="ngModel" required />
                <span class="invalid-feedback" *ngIf="f.submitted && !password.valid">
                  {{'login.PasswordRequired' | translate}}
                </span>
              </div>
            </div>
            <div class="mb-3 row">
              <div class="offset-md-4 col-md-8">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="login-rememberme" name="rememberMe" [(ngModel)]="userLogin.rememberMe">
                  <label class="form-check-label" for="login-rememberme">{{'login.RememberMe' | translate}}</label>
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <div class="offset-md-4 col-md-8">
                <div class="d-flex align-items-stretch">
                  <button *ngIf="!isExternalLogin" type="submit" class="btn btn-primary text-nowrap" [disabled]="isLoading">
                    <i *ngIf="isLoading" class='fa fa-circle-o-notch fa-spin'></i> {{isLoading ? ('login.LoggingIn' | translate) : ('login.Login' | translate)}}
                  </button>
                  <!--
                  <div *ngIf="!isLoading || isExternalLogin" class="d-flex flex-wrap align-items-center justify-content-end w-100">
                    <span class="text-muted text-nowrap me-1">{{'login.ConnectWith' | translate}} </span>
                    <div class="d-flex align-items-center">
                      <button class="btn btn-outline-danger btn-sm  btn-social" (click)="loginWithGoogle()" [disabled]="isLoading"><i class="fa fa-google"></i></button>
                      <button class="btn btn-outline-primary btn-sm btn-social mx-1" (click)="loginWithFacebook()" [disabled]="isLoading"><i class="fa fa-facebook"></i></button>
                      <button class="btn btn-outline-info btn-sm btn-social me-1" (click)="loginWithTwitter()" [disabled]="isLoading"><i class="fa fa-twitter"></i></button>
                      <button class="btn btn-outline-primary btn-sm  btn-social" (click)="loginWithMicrosoft()" [disabled]="isLoading"><i class="fa fa-windows"></i></button>
                    </div>
                  </div>
                  -->
                </div>
              </div>
            </div>
<!--
            <div *ngIf="!isModal" class="col-md-12">
              <hr class="hr-separator" />
            </div>
            <div *ngIf="!isModal" class="col-md-12 last-control-group account-controls text-center">
              <span class="card-text">{{'login.RegisterIntro' | translate}} </span>
              <div class="d-inline-block text-nowrap">
                <a class="card-link text-link" routerLink="/register">{{'login.Register' | translate}}</a>
                |
                <a class="card-link text-link" routerLink="/recoverpassword">{{'login.RecoverPassword' | translate}}</a>
              </div>
            </div>
            -->
          </form>
        </div>
      </div>
    </div>
  </div>
</div>