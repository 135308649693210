export class AddressFullDto {
    addressId: number;
    addressTypeId: number;
    city: string;
    district: string;
    street: string;
    buildingNr: string;
    apartmentNr: string;
    latitude: number;
    longitude: number;
    description: string;
  }