<div>
  <form autocomplete="off" name="flatForm" #f="ngForm" novalidate>


    <ul class="nav nav-tabs mb-3" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link active" id="data-tab" data-bs-toggle="tab" data-bs-target="#data-container" type="button" role="tab" aria-controls="data" aria-selected="true">
          {{'imports.editor.DataTab' | translate}}</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="errors-tab" data-bs-toggle="tab" data-bs-target="#errors-container" type="button" role="tab" aria-controls="errors" aria-selected="false">
          {{'imports.editor.ErrorsTab' | translate}}</button>
      </li>
    </ul>

    <div class="tab-content mb-3" id="flatTabContent">

      <!--  Data  -->
      <div class="tab-pane fade show active" id="data-container" role="tabpanel" aria-labelledby="data-tab">

        <div class="row">
          <label for="importFlatHistoryId" class="col-sm-2 col-form-label">{{'imports.editor.importFlatHistoryId' | translate}}</label>
          <div class="col-sm-10">
            <input type="text" readonly class="form-control-plaintext" id="importFlatHistoryId" name="importFlatHistoryId" [(ngModel)]="importHistory.importFlatHistoryId">
          </div>
        </div>

        <div class="row">
          <label for="fileName" class="col-sm-2 col-form-label">{{'imports.editor.fileName' | translate}}</label>
          <div class="col-sm-10">
            <input type="text" readonly class="form-control-plaintext" id="fileName" name="fileName" [(ngModel)]="importHistory.fileName">
          </div>
        </div>

        <div class="row">
          <label for="importHistoryStageType" class="col-sm-2 col-form-label">{{'imports.editor.importHistoryStageType' | translate}}</label>
          <div class="col-sm-10">
            <input type="text" readonly class="form-control-plaintext" id="importHistoryStageType" name="importHistoryStageType" [(ngModel)]="importHistory.importHistoryStageType">
          </div>
        </div>

        <div class="row">
          <label for="added" class="col-sm-2 col-form-label">{{'imports.editor.added' | translate}}</label>
          <div class="col-sm-10">
            <input type="text" readonly class="form-control-plaintext" id="added" name="added" [(ngModel)]="importHistory.added">
          </div>
        </div>

        <div class="row">
          <label for="updated" class="col-sm-2 col-form-label">{{'imports.editor.updated' | translate}}</label>
          <div class="col-sm-10">
            <input type="text" readonly class="form-control-plaintext" id="updated" name="updated" [(ngModel)]="importHistory.updated">
          </div>
        </div>

        <div class="row">
          <label for="processingTimeInSeconds" class="col-sm-2 col-form-label">{{'imports.editor.processingTimeInSeconds' | translate}}</label>
          <div class="col-sm-10">
            <input type="text" readonly class="form-control-plaintext" id="processingTimeInSeconds" name="processingTimeInSeconds" [(ngModel)]="importHistory.processingTimeInSeconds">
          </div>
        </div>

        <div class="row">
          <label for="fileSize" class="col-sm-2 col-form-label">{{'imports.editor.fileSize' | translate}}</label>
          <div class="col-sm-10">
            <input type="text" readonly class="form-control-plaintext" id="fileSize" name="fileSize" [(ngModel)]="importHistory.fileSize">
          </div>
        </div>

        <div class="row">
          <label for="validationMessages" class="col-sm-2 col-form-label">{{'imports.editor.validationMessages' | translate}}</label>
          <div class="col-sm-10">
            <input type="text" readonly class="form-control-plaintext" id="validationMessages" name="validationMessages" [(ngModel)]="importHistory.validationMessages">
          </div>
        </div>

        <div class="row">
          <label for="exceptions" class="col-sm-2 col-form-label">{{'imports.editor.exceptions' | translate}}</label>
          <div class="col-sm-10">
            <input type="text" readonly class="form-control-plaintext" id="exceptions" name="exceptions" [(ngModel)]="importHistory.exceptions">
          </div>
        </div>

        <div class="row">
          <label for="addDate" class="col-sm-2 col-form-label">{{'imports.editor.addDate' | translate}}</label>
          <div class="col-sm-10">
            <input type="text" readonly class="form-control-plaintext" id="addDate" name="addDate" [(ngModel)]="importHistory.addDate">
          </div>
        </div>

        <div class="row">
          <label for="finishDate" class="col-sm-2 col-form-label">{{'imports.editor.finishDate' | translate}}</label>
          <div class="col-sm-10">
            <input type="text" readonly class="form-control-plaintext" id="finishDate" name="finishDate" [(ngModel)]="importHistory.finishDate">
          </div>
        </div>

      </div>

      <!--  Errors  -->
      <div class="tab-pane fade" id="errors-container" role="tabpanel" aria-labelledby="contact-tab">

        <div class="mb-3">
          <label for="validationMessages" class="form-label">{{'imports.editor.validationMessages' | translate}}</label>
          <textarea class="form-control" id="validationMessagesTextArea" rows="3" name="validationMessages" [(ngModel)]="importHistory.validationMessages"></textarea>
        </div>

        <div class="mb-3">
          <label for="exceptions" class="form-label">{{'imports.editor.exceptions' | translate}}</label>
          <textarea class="form-control" id="exceptionsTextArea" rows="20" name="exceptions" [(ngModel)]="importHistory.exceptions"></textarea>
        </div>

      </div>

    </div>

    <div class="row">
      <div class="col-sm-12">
        <div ngPreserveWhitespaces class="float-end">
          <button type="button" (click)="cancel()" class="btn btn-primary"><i class='fa fa-check'></i> {{'imports.editor.Cancel' | translate}}</button>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>

  </form>
</div>
