import { Injectable } from '@angular/core';
import { InvestmentEndpoint } from './investment-endpoint.service';
import { InvestmentGridDto } from '../models/investment-grid-dto.model';
import { Observable } from 'rxjs/internal/Observable';
import { InvestmentFullDto } from '../models/investment-full-dto.model';
import { InvestmentShortDto } from '../models/investment-active-dto.model';


@Injectable()
export class InvestmentService {

  constructor(
    private investmentEndpoint: InvestmentEndpoint) {
  }

  getInvestmentsGrid(page?: number, pageSize?: number) : Observable<InvestmentGridDto[]> {
    return this.investmentEndpoint.getInvestmentsGridEndpoint<InvestmentGridDto[]>();
  }

  getInvestmentById(investmentId: number) : Observable<InvestmentFullDto> {
    return this.investmentEndpoint.getInvestmentByIdEndpoint<InvestmentFullDto>(investmentId);
  }

  addInvestment(investment: InvestmentFullDto) : Observable<number> {
    return this.investmentEndpoint.addInvestmentEndpoint<number>(investment);
  }

  updateInvestment(investment: InvestmentFullDto) : Observable<number> {
    return this.investmentEndpoint.updateInvestmentEndpoint<number>(investment);
  }

  deleteInvestment(investmentId: number) : Observable<number> {
    return this.investmentEndpoint.deleteInvestmentEndpoint<number>(investmentId);
  }

  getInvestmentsActive() : Observable<InvestmentShortDto[]> {
    return this.investmentEndpoint.getInvestmentsActiveEndpoint<InvestmentShortDto[]>();
  }
}
