import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { EndpointBase } from './endpoint-base.service';
import { ConfigurationService } from './configuration.service';


@Injectable()
export class DictionaryEndpoint extends EndpointBase {

  get idictionariesUrl() { return this.configurations.baseUrl + '/api/dictionary'; }

  constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService) {
    super(http, authService);
  }

  getDictionaryEndpoint<T>(dictionary: string): Observable<T> {
    const endpointUrl = `${this.idictionariesUrl}/${dictionary}/`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDictionaryEndpoint(dictionary));
      }));
  }



}
