import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { EndpointBase } from './endpoint-base.service';
import { ConfigurationService } from './configuration.service';


@Injectable()
export class ImportEndpoint extends EndpointBase {

  get importsUrl() { return this.configurations.baseUrl + '/api/import'; }

  constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService) {
    super(http, authService);
  }

  getFlatHistoryGridEndpoint<T>(page?: number, pageSize?: number): Observable<T> {
    const baseUrl = `${this.importsUrl}/flats/grid`;
    const endpointUrl = page && pageSize ? `${baseUrl}/${page}/${pageSize}` : baseUrl;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getFlatHistoryGridEndpoint(page, pageSize));
      }));
  }

  getFlatHistoryFullEndpoint<T>(importFlatHistoryId: number): Observable<T> {
    const endpointUrl = `${this.importsUrl}/flats/${importFlatHistoryId}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getFlatHistoryFullEndpoint(importFlatHistoryId));
      }));
  }

  uploadFlatsForInvestmentsEndpoint<T>(fileInFormData: FormData): Observable<T> {
    const endpointUrl =  `${this.importsUrl}/flats/upload`;

    return this.http.post<T>(endpointUrl, fileInFormData, this.requestHeadersForFormData).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.uploadFlatsForInvestmentsEndpoint(fileInFormData));
      }));
  }

  invokeFlatImportFromServerEndpoint<T>(): Observable<T> {
    const endpointUrl =  `${this.importsUrl}/flats/invoke`;

    return this.http.post<T>(endpointUrl, null, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.invokeFlatImportFromServerEndpoint());
      }));
  }

}
