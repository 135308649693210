<div>
  <div class="row control-box">
    <div class="col-lg-8">
      <div class="search-box">
        <app-search-box (searchChange)="onSearchChanged($event)" placeholder="{{'flats.management.Search' | translate}}"></app-search-box>
      </div>
    </div>
    <div class="col-lg-4 pe-lg-5">
      <ul class="nav flex-column flex-lg-row justify-content-end">
        <li class="nav-item toolbaritem">
          <a class="nav-link" href="javascript:;" (click)="newFlat()">
            <i class="fa fa-plus-circle"></i> {{'flats.management.NewFlat' | translate}}
          </a>
        </li>
      </ul>
    </div>
  </div>

  <ngx-datatable class="material colored-header sm table-x table-striped table-hover"
                 [loadingIndicator]="loadingIndicator"
                 [rows]="gridRows"
                 [rowHeight]="35"
                 [headerHeight]="37"
                 [footerHeight]="35"
                 [columns]="gridColumns"
                 [scrollbarV]="true"
                 [columnMode]="'force'">
  </ngx-datatable>

  <div class="modal fade" bsModal #editorModal="bs-modal" (onHidden)="onEditorModalHidden()" [config]="{backdrop: 'static'}" tabindex="-1">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h4 *ngIf="!editingFlatNumber" class="modal-title float-start"><i class="fa fa-home"></i> {{'flats.management.NewFlat' | translate}}</h4>
          <h4 *ngIf="editingFlatNumber" class="modal-title float-start"><i class="fa fa-home"></i> {{'flats.management.EditFlat' | translate:editingFlatNumber}}</h4>
          <button type="button" class="btn-close float-end fs-sm" title="Close" (click)="editorModal.hide()"></button>
        </div>
        <div class="modal-body">
          <app-flat-editor #flatEditor></app-flat-editor>
        </div>
      </div>
    </div>
  </div>

</div>

<ng-template #actionsTemplate let-row="row" let-value="value" let-i="index">
  <div>
    <a class="btn btn-link text-link btn-sm" href="javascript:;" (click)="editFlat(row)">
      <i class="fa fa-pencil-square-o" aria-hidden="true"></i> {{'flats.management.Edit' | translate}}
    </a>
  </div>
</ng-template>

<ng-template #dateCellTemplate let-row="row" let-value="value">
  {{ formatDate(value) }}
</ng-template>



