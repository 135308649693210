import { AddressFullDto } from "./address-full-dto.model";
import { InvestmentDocumentDto } from "./investment-document-dto.model";
import { InvestmentImageDto } from "./investment-image-dto.model";

export class InvestmentFullDto {
    investmentId: number;
    investmentName: string;
    developerName: string;
    developerUrl: string;
    priceFrom: number;
    priceTo: number;
    priceMeterFrom: number;
    priceMeterTo: number;
    currency: string;

    squareAreaFrom: number;
    squareAreaTo: number;
    squareAreaUnit: string;

    completionTermTypeFromId?: number;
    completionTermTypeToId?: number;

    floorFrom?: number;
    floorTo?: number;

    parkingTypeIds: number[];
    parkingDesc: string;
    storageRoomDesc: string;
    isLift: boolean;
    distanceFromCenter: string;
    openDays: string;

    advantageTypeIds: number[];

    propertyTypeId: number;

    communication: string;
    roomsHeight: string;

    description: string;
    developerDesc: string;
    address: AddressFullDto = new AddressFullDto();

    images: InvestmentImageDto[] = [];
    docs: InvestmentDocumentDto[] = [];

    isDisabled: boolean;
  }
