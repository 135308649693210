import { Component, OnInit, ViewChild } from '@angular/core';
import { ImportFlatHistoryFullDto } from 'src/app/models/import-flat.model';
import { InvestmentShortDto } from 'src/app/models/investment-active-dto.model';
import { AlertService, MessageSeverity } from 'src/app/services/alert.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { ImportService } from 'src/app/services/import.service';
import { Utilities } from 'src/app/services/utilities';

@Component({
  selector: 'app-imports-details',
  templateUrl: './imports-details.component.html',
  styleUrls: ['./imports-details.component.scss']
})
export class ImportsDetailsComponent implements OnInit {
  public uniqueId: string = Utilities.uniqueId();
  public importHistory: ImportFlatHistoryFullDto = new ImportFlatHistoryFullDto();
  public investments: InvestmentShortDto[];

  private getTranslation: (key: string) => any;

  public changesCancelledCallback: () => void;

  @ViewChild('f')
  public form;

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private importService: ImportService,
    ) {
  }

  ngOnInit() {
    this.getTranslation = (key: string) => this.translationService.getTranslation(key);
  }

  public showImportHistory(importHistoryId: number): void {
    this.importService.getFlatHistoryFull(importHistoryId)
    .subscribe({
      next: result => {
        this.importHistory = result;
      },
      error: error => this.onDataLoadFailed(error)
    });
  }

  public cancel() {
    this.alertService.resetStickyMessage();
    if (this.changesCancelledCallback) {
      this.changesCancelledCallback();
    }
  }

  private onDataLoadFailed(error: any): void {
    this.alertService.stopLoadingMessage();

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }
}
