<div class="app-component app-container">
  <ngx-toasta></ngx-toasta>

  <a class="close-navbar-toggler collapsed" data-bs-toggle="collapse" data-bs-target=".menuItemsContainer">
  </a>

  <nav id="header" class="navbar navbar-expand-lg navbar-dark bg-primary fixed-top">
    <div class="container-xl px-md-3 px-xxl-4">
      <a ngPreserveWhitespaces class="navbar-brand" routerLink="/">
        <img src="assets/images/logo-white.png" class="d-inline-block align-top" alt="logo">
        <span class="d-lg-none d-xl-inline">{{appTitle}}</span>
      </a>
      <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target=".menuItemsContainer">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div *ngIf="isUserLoggedIn" class="collapse navbar-collapse menuItemsContainer">
        <ul class="nav nav-pills flex-column flex-lg-row flex-fill">
          <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a class="nav-link" routerLink=""><i class="fa fa-home"></i></a>
          </li>
          <li class="nav-item" routerLinkActive="active" *ngIf="canViewCustomers">
            <a class="nav-link" routerLink="/customers">{{'mainMenu.Clients' | translate}}</a>
          </li>
          <li class="nav-item" routerLinkActive="active" *ngIf="canViewInvestments">
            <a class="nav-link" routerLink="/investments">{{'mainMenu.Investments' | translate}}</a>
          </li>
          <!-- <li class="nav-item" routerLinkActive="active" *ngIf="canViewProducts">
            <a class="nav-link" routerLink="/products">{{'mainMenu.Products' | translate}}</a>
          </li>
          <li class="nav-item" routerLinkActive="active" *ngIf="canViewOrders">
            <a class="nav-link" routerLink="/orders">{{'mainMenu.Orders' | translate}}</a>
          </li> -->
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" routerLink="/flats">{{'mainMenu.Flats' | translate}}</a>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" routerLink="/imports">{{'mainMenu.Imports' | translate}}</a>
          </li>
          <li class="nav-item ms-lg-auto me-lg-2" routerLinkActive="active">
            <a class="nav-link" routerLink="/settings"><i class="fa fa-cog"></i></a>
          </li>
        </ul>
        <span class="navbar-text notifications-popup d-lg-none d-xl-inline-block">{{'app.Welcome' | translate}}</span>
        <a class="nav-link user-name d-inline-block px-1" [popover]="popTemplate" (onHidden)="markNotificationsAsRead()"
           placement="bottom" [popoverTitle]="notificationsTitle" href="javascript:;" triggers="focus" containerClass="notifications-popup">
          {{userName}}
          <span *ngIf="newNotificationCount > 0" class="badge bg-secondary">{{newNotificationCount}}</span>
        </a>
        <ng-template #popTemplate>
          <app-notifications-viewer [isViewOnly]="true"></app-notifications-viewer>
        </ng-template>
        <span *ngIf="newNotificationCount > 0" class="navbar-text d-none d-lg-inline"> </span>
        <ul class="nav nav-pills flex-column flex-lg-row ms-lg-1">
          <li class="nav-item" (click)="logout()">
            <a class="nav-link" href="javascript:;"><i class="fa fa-sign-out"></i> {{'mainMenu.Logout' | translate}}</a>
          </li>
        </ul>
      </div>

      <div *ngIf="!isUserLoggedIn" class="collapse navbar-collapse menuItemsContainer">
        <ul class="nav nav-pills flex-column flex-lg-row ms-auto">
          <li class="nav-item" routerLinkActive="active">
            <a *ngIf="router.isActive('/about', false); else aboutPageNavTemplate" class="nav-link" routerLink="/login"><i class="fa fa-sign-in"></i></a>
            <ng-template #aboutPageNavTemplate>
              <a class="nav-link" routerLink="/about"><i class="fa fa-info-circle"></i></a>
            </ng-template>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div id="pre-bootstrap" *ngIf="!isAppLoaded" class="prebootStep prebootShow">
    <div class="messaging">
      <h1>
        Loaded!
      </h1>
      <p>
        Powered by Propone
      </p>

    </div>
  </div>

  <main class="container-xl px-3 px-md-4 px-xxl-5">
    <router-outlet></router-outlet>
    <div class="footer-height"></div>
  </main>

  <footer class="footer fixed-bottom">
    <div class="container">
      <p class="text-center text-muted">
        <span style="font-style:italic">Propone</span> &copy; {{getYear()}}
      </p>
    </div>
  </footer>

  <div *ngIf="shouldShowLoginModal" class="modal fade login-control" bsModal #loginModal="bs-modal" (onShown)="onLoginModalShown()" (onHidden)="onLoginModalHidden()" (onHide)="onLoginModalHide()"
       [config]="{backdrop: 'static'}" tabindex="-1">
    <div class="modal-dialog modal-lg h-75 d-flex flex-column justify-content-center my-0">
      <div class="modal-content">
        <div class="modal-body">
          <app-login #loginControl [isModal]="true"></app-login>
        </div>
      </div>
    </div>
  </div>
</div>
