import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { EndpointBase } from './endpoint-base.service';
import { ConfigurationService } from './configuration.service';
import { InvestmentFullDto } from '../models/investment-full-dto.model';

@Injectable()
export class InvestmentEndpoint extends EndpointBase {

  get investmentsUrl() { return this.configurations.baseUrl + '/api/bo'; }

  constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService) {
    super(http, authService);
  }

  getInvestmentsGridEndpoint<T>(page?: number, pageSize?: number): Observable<T> {
    const baseUrl = `${this.investmentsUrl}/grid/`;
    const endpointUrl = page && pageSize ? `${baseUrl}/${page}/${pageSize}` : baseUrl;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getInvestmentsGridEndpoint(page, pageSize));
      }));
  }

  getInvestmentByIdEndpoint<T>(investmentId: number): Observable<T> {
    const endpointUrl =  `${this.investmentsUrl}/${investmentId}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getInvestmentByIdEndpoint(investmentId));
      }));
  }

  addInvestmentEndpoint<T>(investmentObject: InvestmentFullDto): Observable<T> {
    const endpointUrl =  `${this.investmentsUrl}/`;
    return this.http.post<T>(endpointUrl, JSON.stringify(investmentObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.addInvestmentEndpoint(investmentObject));
      }));
  }

  updateInvestmentEndpoint<T>(investmentObject: InvestmentFullDto): Observable<T> {
    const endpointUrl =  `${this.investmentsUrl}/`;
    return this.http.put<T>(endpointUrl, JSON.stringify(investmentObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.updateInvestmentEndpoint(investmentObject));
      }));
  }

  deleteInvestmentEndpoint<T>(investmentId: number): Observable<T> {
    const endpointUrl =  `${this.investmentsUrl}/${investmentId}`;
    return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.deleteInvestmentEndpoint(investmentId));
      }));
  }

  getInvestmentsActiveEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this.investmentsUrl}/active`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getInvestmentsActiveEndpoint());
      }));
  }
}
