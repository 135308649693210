<div>
  <div class="row control-box">
    <div class="col-lg-8">
      <div class="search-box">
        <app-search-box (searchChange)="onSearchChanged($event)" placeholder="{{'investments.management.Search' | translate}}"></app-search-box>
      </div>
    </div>
    <div class="col-lg-4 pe-lg-5">
      <ul class="nav flex-column flex-lg-row justify-content-end">
        <li *ngIf="canManageInvestments" class="nav-item toolbaritem">
          <a class="nav-link" href="javascript:;" (click)="newInvestment()">
            <i class="fa fa-plus-circle"></i> {{'investments.management.NewInvestment' | translate}}
          </a>
        </li>
      </ul>
    </div>
  </div>

  <ngx-datatable class="material colored-header sm table-x table-striped table-hover"
                 [loadingIndicator]="loadingIndicator"
                 [rows]="gridRows"
                 [rowHeight]="35"
                 [headerHeight]="37"
                 [footerHeight]="35"
                 [columns]="gridColumns"
                 [scrollbarV]="true"
                 [columnMode]="'force'">
  </ngx-datatable>

  <ng-template #actionsTemplate let-row="row" let-value="value" let-i="index">
    <div *ngIf="canManageInvestments">
      <a class="btn btn-link text-link btn-sm" href="javascript:;" (click)="editInvestment(row)"><i class="fa fa-pencil-square-o" aria-hidden="true"></i> {{'investments.management.Edit' | translate}}</a>|
      <a class="btn btn-link text-link btn-sm" href="javascript:;" (click)="addFlat(row.id)"><i class="fa fa-plus" aria-hidden="true"></i> {{'investments.management.AddFlat' | translate}}</a>
    </div>
  </ng-template>

  <ng-template #dateCellTemplate let-row="row" let-value="value">
    {{ formatDate(value) }}
  </ng-template>

  <div class="modal fade" bsModal #investmentEditorModal="bs-modal" (onHidden)="onEditorModalHidden()" [config]="{backdrop: 'static'}" tabindex="-1">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h4 *ngIf="!editingInvestmentName" class="modal-title float-start"><i class="fa fa-home"></i> {{'investments.management.NewInvestment' | translate}}</h4>
          <h4 *ngIf="editingInvestmentName" class="modal-title float-start"><i class="fa fa-home"></i> {{'investments.management.EditInvestment' | translate:editingInvestmentName}}</h4>
          <button type="button" class="btn-close float-end fs-sm" title="Close" (click)="investmentEditorModal.hide()"></button>
        </div>
        <div class="modal-body">
          <app-investment-editor #investmentEditor></app-investment-editor>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" bsModal #flatEditorModal="bs-modal" (onHidden)="onEditorModalHidden()" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title float-start"><i class="fa fa-home"></i> {{'flats.management.NewFlat' | translate}}</h4>
        <button type="button" class="btn-close float-end fs-sm" title="Close" (click)="flatEditorModal.hide()"></button>
      </div>
      <div class="modal-body">
        <app-flat-editor #flatEditor></app-flat-editor>
      </div>
    </div>
  </div>
</div>