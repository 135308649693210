import { Component, OnInit, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs';
import { DictDto } from 'src/app/models/dict-dto.model';
import { DocumentFilesWithType } from 'src/app/models/document-files-with-type.model';
import { DocumentType, ImageType } from 'src/app/models/enums';
import { FlatShortDto } from 'src/app/models/flat-short-dto.model';
import { ImageFilesWithType } from 'src/app/models/image-files-with-type.model';
import { InvestmentDocumentDto } from 'src/app/models/investment-document-dto.model';
import { InvestmentFullDto } from 'src/app/models/investment-full-dto.model';
import { InvestmentImageDto } from 'src/app/models/investment-image-dto.model';
import { AlertService, MessageSeverity } from 'src/app/services/alert.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { DictionaryService } from 'src/app/services/dictionary.service';
import { FileService } from 'src/app/services/file.service';
import { FlatService } from 'src/app/services/flat.service';
import { InvestmentService } from 'src/app/services/investment.service';
import { Utilities } from 'src/app/services/utilities';

@Component({
  selector: 'app-investment-editor',
  templateUrl: './investment-editor.component.html',
  styleUrls: ['./investment-editor.component.scss']
})
export class InvestmentEditorComponent implements OnInit {
  public uniqueId: string = Utilities.uniqueId();
  public investmentEdit: InvestmentFullDto = new InvestmentFullDto();
  public completionTermTypes: DictDto[];
  public parkingTypes: DictDto[];
  public invAdvantageTypes: DictDto[];
  public propertyTypes: DictDto[];
  public addressTypes: DictDto[];
  public documentTypes: DictDto[];
  private gT: (key: string) => any;
  public ImageTypeEnum = ImageType;
  public DocumentTypeEnum = DocumentType;
  public selectedImages: File[] = [];
  public selectedPlans: File[] = [];
  public selectedDocs: {file: File, typeId: number }[] = [];
  public existingPictures: InvestmentImageDto[];
  public existingPlans: InvestmentImageDto[];
  public existingDocs: InvestmentDocumentDto[];
  public documentTypeSelectedId: number = DocumentType.SCHEDULE;
  public loadingIndicator: boolean;
  public flatColumns: any[] = [];
  public flatRows: FlatShortDto[] = [];


  public isSavingInProgress = false;
  public showValidationErrors = false;

  public formResetToggle = true;

  public changesSavedCallback: () => void;
  public changesFailedCallback: () => void;
  public changesCancelledCallback: () => void;

  @ViewChild('f')
  public form;

  constructor(
    private alertService: AlertService,
    private investmentService: InvestmentService,
    private dictionaryService: DictionaryService,
    private translationService: AppTranslationService,
    private fileService: FileService,
    private flatService: FlatService) {
  }

  ngOnInit() {
    this.gT = (key: string) => this.translationService.getTranslation(key);

    forkJoin({
      termTypes: this.dictionaryService.getCompletionTermTypes(),
      parkingTypes: this.dictionaryService.getParkingTypes(),
      advTypes: this.dictionaryService.getInvAdvantageTypes(),
      propTypes: this.dictionaryService.getPropertyTypes(),
      addrTypes: this.dictionaryService.getAddressTypes(),
      docTypes: this.dictionaryService.getDocumentTypes(),

      }).subscribe({
        next: (results) => {
          this.completionTermTypes = results.termTypes;
          this.parkingTypes = results.parkingTypes;
          this.invAdvantageTypes = results.advTypes;
          this.propertyTypes = results.propTypes;
          this.addressTypes = results.addrTypes;
          this.documentTypes = results.docTypes;
        },
        error: error => this.onDataLoadFailed(error)
    });

    this.flatColumns = [
      { prop: 'flatId', name: this.gT('flats.management.flatId'),  },
      { prop: 'flatNumber', name: this.gT('flats.management.flatNumber'), },
      { prop: 'developerCode', name: this.gT('flats.management.developerCode'), },
      { prop: 'stageType', name: this.gT('flats.management.stageType'), },
      { prop: 'statusType', name: this.gT('flats.management.statusType'), },
      { prop: 'price', name: this.gT('flats.management.price'), },
    ];
  }

  public newInvestment(): void {
    this.investmentEdit = new InvestmentFullDto();
  }

  public editInvestment(investmentId: number): void {

    this.investmentService.getInvestmentById(investmentId)
    .subscribe({
      next: result => {
        this.investmentEdit = result;
        this.existingPictures = this.investmentEdit.images.filter(image => image.imageTypeId === ImageType.PICTURE);
        this.existingPlans = this.investmentEdit.images.filter(image => image.imageTypeId === ImageType.FLOOR_PLAN);
        this.existingDocs = this.investmentEdit.docs;

        this.flatService.getFlatsForInvestment(investmentId)
        .subscribe({
          next: result => this.flatRows = result,
          error: error => this.onDataLoadFailed(error)
        });
      },
      error: error => this.onDataLoadFailed(error)
    });
  }

  public save(): void {

    this.isSavingInProgress = true;
    this.alertService.startLoadingMessage('Saving changes... ');

    if (this.investmentEdit.investmentId) {
      this.investmentService.updateInvestment(this.investmentEdit)
      .subscribe({
        next: _ => {
          this.uploadImages(this.investmentEdit.investmentId);
          this.uploadDocuments(this.investmentEdit.investmentId);
          this.saveSuccessHelper();
          this.alertService.showMessage(this.gT('investments.editor.InvestmentEdit'),
            this.gT('investments.editor.InvestmentEditSuccess'), MessageSeverity.success);
        },
        error: error => this.saveFailedHelper(error)
      });
    } else {
      this.investmentService.addInvestment(this.investmentEdit)
        .subscribe({
          next: investmentId => {
            if (investmentId > 0) {
              this.uploadImages(investmentId);
              this.uploadDocuments(investmentId);
            } else {
              this.alertService.showStickyMessage('Save Error', this.gT('investments.editor.ImageSaveError'), MessageSeverity.error);
            }
            this.saveSuccessHelper();
            this.alertService.showMessage(this.gT('investments.editor.InvestmentAdd'),
              this.gT('investments.editor.InvestmentAddSuccess'), MessageSeverity.success);
          },
          error: error => this.saveFailedHelper(error)
        });
    }
  }

  private saveSuccessHelper(): void {

    this.isSavingInProgress = false;
    this.alertService.stopLoadingMessage();
    this.showValidationErrors = false;
    this.resetForm();

    if (this.changesSavedCallback) {
      this.changesSavedCallback();
    }
  }

  private saveFailedHelper(error: any) {
    this.isSavingInProgress = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Save Error', 'The below errors occured whilst saving your changes:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);

    if (this.changesFailedCallback) {
      this.changesFailedCallback();
    }
  }

  public cancel() {
    this.showValidationErrors = false;
    this.resetForm();

    this.alertService.showMessage('Cancelled', this.gT('investments.editor.CancelAction'), MessageSeverity.default);
    this.alertService.resetStickyMessage();

    if (this.changesCancelledCallback) {
      this.changesCancelledCallback();
    }
  }

  public resetForm(replace = false) {
    if (!replace) {
      this.form.reset();
      this.documentTypeSelectedId = DocumentType.SCHEDULE;
      this.clearSelectedFiles();
      this.flatRows = [];
    } else {
      this.formResetToggle = false;

      setTimeout(() => {
        this.formResetToggle = true;
      });
    }
  }

  private clearSelectedFiles() {
    this.selectedImages = [];
    this.selectedPlans = [];
    this.selectedDocs = [];
  }

  private onDataLoadFailed(error: any): void {
    this.alertService.stopLoadingMessage();

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }


  public onImagesSelected(files: FileList, type: ImageType): void {
    const typeMap = {
      [ImageType.PICTURE]: this.selectedImages,
      [ImageType.FLOOR_PLAN]: this.selectedPlans,
    };

    const fileListArray = Array.from(files);
    for (const file of fileListArray) {

      const selectedFiles = typeMap[type];
      if (selectedFiles) {
        selectedFiles.push(file);
      }
    }
  }

  public humanFileSize(bytes: number): string {
    if (Math.abs(bytes) < 1024) {
      return bytes + ' B';
    }
    const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let u = -1;
    do {
      bytes /= 1024;
      u++;
    } while (Math.abs(bytes) >= 1024 && u < units.length - 1);
    return bytes.toFixed(1) + ' ' + units[u];
  }

  private uploadImages(investmentId: number): void {
    if (!this.selectedImages.length && !this.selectedPlans.length) {
      return;
    }
    const images: ImageFilesWithType[] = [
      { images: this.selectedImages, typeId: ImageType.PICTURE },
      { images: this.selectedPlans, typeId: ImageType.FLOOR_PLAN }
    ];
    this.fileService.uploadImagesForInvestment(investmentId, images)
    .subscribe({
      next: _ => {
        this.selectedImages = [];
        this.selectedPlans = [];
      },
      error: error => this.saveFailedHelper(error)
    });
  }

  public deleteImage(image: InvestmentImageDto, imageType: ImageType): void {
    this.fileService.deleteImageForInvestment(image.investmentImageId)
    .subscribe({
      next: rowsAffected => {
        if (rowsAffected > 0) {
          this.alertService.showMessage(this.gT('investments.editor.ImageDeleted'), this.gT('investments.editor.ImageDeletedSuccess'), MessageSeverity.success);
          switch (imageType) {
            case ImageType.PICTURE:
              this.existingPictures = this.existingPictures.filter(img => img.investmentImageId !== image.investmentImageId);
              break;
            case ImageType.FLOOR_PLAN:
              this.existingPlans = this.existingPlans.filter(img => img.investmentImageId !== image.investmentImageId);
              break;
            default:
              throw new Error(`Not supported imageType: ${imageType}`);
          }
        }
      },
      error: error => this.saveFailedHelper(error)
    });
  }

  public mapDocumentTypeIdToTypeName(documentTypeId: number): string{
    const keys = Object.keys(this.DocumentTypeEnum).filter((x) => this.DocumentTypeEnum[x] === documentTypeId);
    return keys.length > 0 ? keys[0] : null;
  }

  public deleteDocument(document: InvestmentDocumentDto): void {
    this.fileService.deleteDocumentForInvestment(document.investmentDocumentId)
    .subscribe({
      next: rowsAffected => {
        if (rowsAffected > 0) {
          this.alertService.showMessage(this.gT('investments.editor.ImageDeleted'), this.gT('investments.editor.ImageDeletedSuccess'), MessageSeverity.success);
          this.existingDocs = this.existingDocs.filter(doc => doc.investmentDocumentId !== document.investmentDocumentId);
        }
      },
      error: (error: any) => this.saveFailedHelper(error)
    });
  }

  public onDocumentsSelected(files: FileList, documentTypeId: number): void {
    const fileListArray = Array.from(files);
    for (const fileItem of fileListArray) {
      this.selectedDocs.push({ file: fileItem, typeId: documentTypeId});
    }
  }

  private uploadDocuments(investmentId: number): void {
    if (!this.selectedDocs.length && !this.selectedDocs.length) {
      return;
    }
    const docFilesWithType: DocumentFilesWithType[] = this.selectedDocs.reduce((acc, curr) => {
      const index = acc.findIndex((group) => group.typeId === curr.typeId);
      if (index !== -1) {
        acc[index].docs.push(curr.file);
      } else {
        acc.push({
          typeId:curr.typeId,
          docs: [curr.file],
        });
      }
      return acc;
    }, []);

    this.fileService.uploadDocumentsForInvestment(investmentId, docFilesWithType)
    .subscribe({
      next: _ => {
        this.selectedDocs = [];
      },
      error: (error: any) => this.saveFailedHelper(error)
    });
  }
}
