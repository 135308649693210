import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ImportEndpoint } from './import-endpoint.service';
import { ImportFlatGridDto, ImportFlatHistoryFullDto } from '../models/import-flat.model';


@Injectable()
export class ImportService {

  constructor(
    private importEndpoint: ImportEndpoint) {
  }

  getFlatHistoryGrid() : Observable<ImportFlatGridDto[]> {
    return this.importEndpoint.getFlatHistoryGridEndpoint<ImportFlatGridDto[]>();
  }

  getFlatHistoryFull(importFlatHistoryId: number) : Observable<ImportFlatHistoryFullDto> {
    return this.importEndpoint.getFlatHistoryFullEndpoint<ImportFlatHistoryFullDto>(importFlatHistoryId);
  }

  uploadFlatsForInvestments(flatImportFile: File) : Observable<number> {
    const formData = new FormData();
    formData.append('files', flatImportFile);
    return this.importEndpoint.uploadFlatsForInvestmentsEndpoint<number>(formData);
  }

  invokeFlatImportFromServer(): Observable<number> {
    return this.importEndpoint.invokeFlatImportFromServerEndpoint<number>();
  }
}
