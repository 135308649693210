import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { DictDto } from '../models/dict-dto.model';
import { DictionaryEndpoint } from './dictionary-endpoint.service';


@Injectable()
export class DictionaryService {

  constructor(
    private dictionaryEndpoint: DictionaryEndpoint) {
  }

  getCompletionTermTypes() : Observable<DictDto[]> {
    return this.dictionaryEndpoint.getDictionaryEndpoint<DictDto[]>('term-types');
  }

  getParkingTypes() : Observable<DictDto[]> {
    return this.dictionaryEndpoint.getDictionaryEndpoint<DictDto[]>('parking-types');
  }

  getInvAdvantageTypes() : Observable<DictDto[]> {
    return this.dictionaryEndpoint.getDictionaryEndpoint<DictDto[]>('inv-adv-types');
  }

  getFlatAdvantageTypes() : Observable<DictDto[]> {
    return this.dictionaryEndpoint.getDictionaryEndpoint<DictDto[]>('flat-adv-types');
  }

  getPropertyTypes() : Observable<DictDto[]> {
    return this.dictionaryEndpoint.getDictionaryEndpoint<DictDto[]>('property-types');
  }

  getAddressTypes() : Observable<DictDto[]> {
    return this.dictionaryEndpoint.getDictionaryEndpoint<DictDto[]>('address-types');
  }

  getDocumentTypes() : Observable<DictDto[]> {
    return this.dictionaryEndpoint.getDictionaryEndpoint<DictDto[]>('document-types');
  }

  getStageTypes() : Observable<DictDto[]> {
    return this.dictionaryEndpoint.getDictionaryEndpoint<DictDto[]>('stage-types');
  }

  getStatusTypes() : Observable<DictDto[]> {
    return this.dictionaryEndpoint.getDictionaryEndpoint<DictDto[]>('status-types');
  }

  getWorldDirectionTypes() : Observable<DictDto[]> {
    return this.dictionaryEndpoint.getDictionaryEndpoint<DictDto[]>('world-direction-types');
  }

  getSurroundingTypes() : Observable<DictDto[]> {
    return this.dictionaryEndpoint.getDictionaryEndpoint<DictDto[]>('surrounding-types');
  }

  getPromotionTypes() : Observable<DictDto[]> {
    return this.dictionaryEndpoint.getDictionaryEndpoint<DictDto[]>('promotion-types');
  }
}
